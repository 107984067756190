import React from "react";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';


function CarouselTile(props) {
    const { t } = useTranslation();

    return (
        <>
            <a href={props.link} as={Link}>
                <div className="item" data-value={props.value}>
                    <div className="carousel-item">
                        <div className="carousel-item-icon">
                            <img alt="carousel-item-icon" src={props.icon}></img>
                        </div>
                        <h3 className="carousel-item-heading">{t([props.title])}</h3>
                        <p className="carousel-item-text">{t([props.description])}</p>

                        <div className="carousel-item-arrow">
                            <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M25.6992 13.3242C26.4316 12.5918 26.4316 11.4023 25.6992 10.6699L16.3242 1.29492C15.5918 0.5625 14.4023 0.5625 13.6699 1.29492C12.9375 2.02734 12.9375 3.2168 13.6699 3.94922L19.8516 10.125H1.875C0.837891 10.125 0 10.9629 0 12C0 13.0371 0.837891 13.875 1.875 13.875H19.8457L13.6758 20.0508C12.9434 20.7832 12.9434 21.9727 13.6758 22.7051C14.4082 23.4375 15.5977 23.4375 16.3301 22.7051L25.7051 13.3301L25.6992 13.3242Z" fill="#E9521D" />
                            </svg>
                        </div>

                    </div>
                </div>
            </a>
        </>
    );
}

export default CarouselTile;