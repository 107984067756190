import useEmblaCarousel from 'embla-carousel-react'
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'
import React, { useCallback, useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useTranslation } from 'react-i18next';

type Axis = 'x' | 'y'

const Carousel = ({ children }: { children: React.ReactNode }) => {
    const { t } = useTranslation();
    const [axis] = useState<Axis>('x')
    const [forceWheelAxis] = useState<Axis>('y')
    const [target] = useState<Element | undefined>()
    const [emblaRef, embla] = useEmblaCarousel({ loop: false, skipSnaps: true, axis }, [
        WheelGesturesPlugin({
            forceWheelAxis,
            target,
        }),
    ])

    const DotButton = ({ selected, onClick }: { selected: boolean; onClick(): void }) => (
        <button className={`carousel__dot${selected ? ' is-selected' : ''}`} onClick={onClick} />
    )

    const PrevButton = ({ enabled, onClick }: { enabled: boolean; onClick(): void }) => (
        <button className="carousel__button carousel__button--prev" onClick={onClick} disabled={!enabled}>
            <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="23.5" cy="23.5" r="23.5" transform="rotate(-180 23.5 23.5)" fill="#E9521D" fillOpacity="0.2" />
                <g clipPath="url(#clip0_108_563)">
                    <path d="M11.3008 22.6758C10.5684 23.4082 10.5684 24.5977 11.3008 25.3301L20.6758 34.7051C21.4082 35.4375 22.5977 35.4375 23.3301 34.7051C24.0625 33.9727 24.0625 32.7832 23.3301 32.0508L17.1484 25.875L35.125 25.875C36.1621 25.875 37 25.0371 37 24C37 22.9629 36.1621 22.125 35.125 22.125L17.1543 22.125L23.3242 15.9492C24.0566 15.2168 24.0566 14.0273 23.3242 13.2949C22.5918 12.5625 21.4023 12.5625 20.6699 13.2949L11.2949 22.6699L11.3008 22.6758Z" fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_108_563">
                        <rect width="26.25" height="30" fill="white" transform="translate(37 39) rotate(-180)" />
                    </clipPath>
                </defs>
            </svg>
        </button>
    )

    const NextButton = ({ enabled, onClick }: { enabled: boolean; onClick(): void }) => (
        <button className="carousel__button carousel__button--next" onClick={onClick} disabled={!enabled}>
            <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="23.5" cy="23.5" r="23.5" fill="#E9521D" fillOpacity="0.2" />
                <g clipPath="url(#clip0_108_530)">
                    <path d="M35.6992 24.3242C36.4316 23.5918 36.4316 22.4023 35.6992 21.6699L26.3242 12.2949C25.5918 11.5625 24.4023 11.5625 23.6699 12.2949C22.9375 13.0273 22.9375 14.2168 23.6699 14.9492L29.8516 21.125H11.875C10.8379 21.125 10 21.9629 10 23C10 24.0371 10.8379 24.875 11.875 24.875H29.8457L23.6758 31.0508C22.9434 31.7832 22.9434 32.9727 23.6758 33.7051C24.4082 34.4375 25.5977 34.4375 26.3301 33.7051L35.7051 24.3301L35.6992 24.3242Z" fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_108_530">
                        <rect width="26.25" height="30" fill="white" transform="translate(10 8)" />
                    </clipPath>
                </defs>
            </svg>
        </button>
    )

    const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
    const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [scrollSnaps, setScrollSnaps] = useState<number[]>([])

    const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [embla])
    const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla])
    const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla])

    useEffect(() => {
        if (embla) {
            const onSelect = () => {
                setSelectedIndex(embla.selectedScrollSnap())
                setPrevBtnEnabled(embla.canScrollPrev())
                setNextBtnEnabled(embla.canScrollNext())
            }

            setScrollSnaps(embla.scrollSnapList())
            embla.on('select', onSelect)
            onSelect()
        }
    }, [embla])

    return (
        <>
            <div className="carousel-container">
                <Row>
                    <Col xs={12} md={4} className='col-left-section-carousel'>
                        <div className="left-section-carousel">
                            <h1 className='carousel-heading'>{t('ourServices.carousel-heading')}</h1>
                            <p className='carousel-text'></p>

                            <div className="carousel__dots">
                                {scrollSnaps.map((snap, index) => (
                                    <DotButton selected={index === selectedIndex} onClick={() => scrollTo(index)} key={index} />
                                ))}
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={8} className='col-right-section-carousel'>
                        <div className="right-section-carousel">
                            <div className="carousel__dots__right">
                                {scrollSnaps.map((snap, index) => (
                                    <DotButton selected={index === selectedIndex} onClick={() => scrollTo(index)} key={index} />
                                ))}
                            </div>
                            <div className="carousel" data-axis={axis}>
                                <div ref={emblaRef} className="carousel__viewport">

                                    <div className="carousel__container">
                                        {React.Children.map(children, (Child, index) => (
                                            <div className="carousel__slide" key={index}>
                                                <div className="carousel__slide__inner">{Child}</div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                {/* <div className="embla__dots">
                                {scrollSnaps.map((snap, index) => (
                                    <DotButton selected={index === selectedIndex} onClick={() => scrollTo(index)} key={index} />
                                ))} </div> */}
                                <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
                                <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default Carousel
