import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Image } from 'react-bootstrap';

export const TeamPage = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>Team</title>
                <meta name="description" content="Team Page  for Bailänder" />
            </Helmet>
            <div className='berater-wraper'>
                <Container>
                    <Row className="justify-content-md-center" style={{marginBottom: 100}} >
                        <Col md={12} >
                            <Image className='image-gruppe-top' style={{ width: '100%' }} src='../img/title-images/bailaender_gruppe_v2.jpg' fluid alt='team-title'></Image>
                        </Col>
                        <Col md={12} lg={8} >
                            {t('team.title').split('/n').map(c => {
                                return (<h1  className='main-page-heading-subtitle' > {c} </h1>)
                            })}
                            
                        </Col>
                    </Row>
                    {/* <Container className='service-page-content'>
                        <Row>
                            <Col md={12} lg={{ span: 5, offset: 1 }}>
                                <p className='text-services-pages'>{t('payroll.text1')}</p>
                            </Col>

                            <Col md={12} lg={5}>
                                <p className='text-services-pages'>{t('payroll.text2')}</p>
                            </Col>
                        </Row>
                    </Container>

                    <Row>
                        <Col md={12} style={{marginBottom: 50}}>
                            <h1>{t('team.headline')}</h1>
                        </Col>
                    </Row> */}
                    <Row >
                        <Col sm={12} md={5}>
                            <img className='image_berater_sw' src='./img/mitarbeiter/mitarbeiter_bailaender_sw.jpg' style={{ display: 'block', margin: '0 auto auto' }} alt=''></img>
                            <img className='image_berater_color' src='./img/mitarbeiter/mitarbeiter_bailaender.jpg' style={{ display: 'block', margin: '0 auto auto' }} alt=''></img>
                        </Col>
                        <Col sm={0} md={1}>
                            {/* EMPTY COL */}
                        </Col>
                        <Col sm={12} md={5} className="berater-wraper-upper-col2">
                            <h2><span style={{ color: '#E9521D' }}>Marcel</span> <span style={{ color: '#2D2A29' }}>Bailänder</span></h2>
                            <p>{t('general.taxConsultant')}<br />
                                Diplom-Finanzwirt (FH)</p>
                            <p style={{ textAlign: 'justify' }}>{t('team.discriptionBailänder')}</p>
                        </Col>
                        <Col sm={0} md={1}>
                            {/* EMPTY COL */}
                        </Col>
                    </Row>
                    <Row className='team-page-berater-bottom'>
                        <Col sm={0} md={1}>
                            {/* EMPTY COL */}
                        </Col>
                        <Col sm={12} md={5} className='berater-wraper-bottom-col1' style={{ textAlign: 'right' }}>
                            <h2 ><span style={{ color: '#E9521D' }}>Johannes</span> <span style={{ color: '#2D2A29' }}>Miksik</span></h2>
                            <p>{t('general.taxConsultant')}<br />
                            Volkswirt (B.Sc.)<br />
                            Zertifizierter Berater für die Immobilienbesteuerung <br />und -verwaltung ( IFU/ISM gGmbH)</p>
                            <p style={{ textAlign: 'justify' }}>{t('team.discriptionMiksik')}</p>
                        </Col>
                        <Col sm={0} md={1}>
                            {/* EMPTY COL */}
                        </Col>
                        <Col sm={12} md={5}>
                            <img className='image_berater_sw' src='./img/mitarbeiter/mitarbeiter_miksik_sw.jpg' style={{ display: 'block', margin: '0 auto auto' }} alt=''></img>
                            <img className='image_berater_color' src='./img/mitarbeiter/mitarbeiter_miksik.jpg' style={{ display: 'block', margin: '0 auto auto' }} alt=''></img>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container className='employe-section'>

                <Row>
                    <Col xl={3} md={6} xs={12} className='col-mitarbeiter'>
                        <img className='image_mitarbeiter_sw' src='./img/mitarbeiter/mitarbeiter_bjelobrk_sw.jpg' alt=''></img>
                        <img className='image_mitarbeiter_color' src='./img/mitarbeiter/mitarbeiter_bjelobrk.jpg' alt=''></img>
                        <div style={{ textAlign: 'center' }}>
                            <h2 ><span style={{ color: '#E9521D' }}>Frau</span> <span style={{ color: '#2D2A29' }}>Bjelobrk</span></h2>
                            <p>{t('team.specialistFor')}<br />{t('team.AccountingAndWages')}</p>
                        </div>
                    </Col>
                    <Col xl={3} md={6} xs={12} className='col-mitarbeiter'>
                        <img className='image_mitarbeiter_sw' src='./img/mitarbeiter/mitarbeiter_frantz_sw.jpg' alt=''></img>
                        <img className='image_mitarbeiter_color' src='./img/mitarbeiter/mitarbeiter_frantz.jpg' alt=''></img>
                        <div style={{ textAlign: 'center' }}>
                            <h2 ><span style={{ color: '#E9521D' }}>Frau</span> <span style={{ color: '#2D2A29' }}>Frantz</span></h2>
                            <p>{t('general.taxSpecialistF')}</p>
                        </div>
                    </Col>
                    <Col xl={3} md={6} xs={12} className='col-mitarbeiter'>
                        <img className='image_mitarbeiter_sw' src='./img/mitarbeiter/mitarbeiter_oberhofer_sw.jpg' alt=''></img>
                        <img className='image_mitarbeiter_color' src='./img/mitarbeiter/mitarbeiter_oberhofer.jpg' alt=''></img>
                        <div style={{ textAlign: 'center' }}>
                            <h2 ><span style={{ color: '#E9521D' }}>Frau</span> <span style={{ color: '#2D2A29' }}>Oberhofer</span></h2>
                            <p>{t('general.taxSpecialistF')}</p>
                        </div>
                    </Col>
                    <Col xl={3} md={6} xs={12} className='col-mitarbeiter'>
                        <img className='image_mitarbeiter_sw' src='./img/mitarbeiter/mitarbeiter_ossner_sw.jpg' alt=''></img>
                        <img className='image_mitarbeiter_color' src='./img/mitarbeiter/mitarbeiter_ossner.jpg' alt=''></img>
                        <div style={{ textAlign: 'center' }}>
                            <h2 ><span style={{ color: '#E9521D' }}>Frau</span> <span style={{ color: '#2D2A29' }}>Ossner</span></h2>
                            <p>{t('general.taxSpecialistF')}</p>
                        </div>
                    </Col>
                    <Col xl={3} md={6} xs={12} className='col-mitarbeiter'>
                        <img className='image_mitarbeiter_sw' src='./img/mitarbeiter/mitarbeiter_sopaj_sw.jpg' alt=''></img>
                        <img className='image_mitarbeiter_color' src='./img/mitarbeiter/mitarbeiter_sopaj.jpg' alt=''></img>
                        <div style={{ textAlign: 'center' }}>
                            <h2 ><span style={{ color: '#E9521D' }}>Frau</span> <span style={{ color: '#2D2A29' }}>Sopaj</span></h2>
                            <p>{t('general.taxSpecialistF')}</p>
                        </div>
                    </Col>
                    <Col xl={3} md={6} xs={12} className='col-mitarbeiter'>
                        <img className='image_mitarbeiter_sw' src='./img/mitarbeiter/mitarbeiter_huber_sw.jpg' alt=''></img>
                        <img className='image_mitarbeiter_color' src='./img/mitarbeiter/mitarbeiter_huber.jpg' alt=''></img>
                        <div style={{ textAlign: 'center' }}>
                            <h2 ><span style={{ color: '#E9521D' }}>Frau</span> <span style={{ color: '#2D2A29' }}>Huber</span></h2>
                            <p>{t('general.taxSpecialistF')}</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default TeamPage;
