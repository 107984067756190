import { useTranslation } from 'react-i18next';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';

function Header() {
  const { t } = useTranslation();
  

  return (
    <div className='header'>

      {/* MENUBAR */}
      <Navbar className='navbar-custom' style={{ padding: 0 }} collapseOnSelect  expand="lg" bg="dark" variant="dark">
        <Navbar.Brand style={{ padding: 0 }} as={Link} to="/">
          <img className='header-logo' src='../logo/bailaender_logo.svg' alt='logo-bailaender'></img>
          <img className='header-logo-signet' src='../logo/bailaender_signet.png' alt='logo-bailaender-signet' height={90}></img>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse  id="responsive-navbar-nav">
          <Nav className="me-auto">
          </Nav>
          <Nav style={{ marginBottom: 10 }}>
            <NavDropdown className='navlink-menubar' autoClose="false" title={t('header.services')} id="collasible-nav-dropdown" renderMenuOnMount={true}>
              <NavDropdown.Item eventKey="1" as={Link} to="/steueroptimierung">
                {t("ourServices.title1")}
              </NavDropdown.Item>

              <NavDropdown.Item eventKey="2" as={Link} to="/immobilien">
                {t("ourServices.title2")}
              </NavDropdown.Item>

              <NavDropdown.Item eventKey="3" as={Link} to="/erbschaft">
                {t("ourServices.title3")}
              </NavDropdown.Item>

              <NavDropdown.Item eventKey="4" as={Link} to="/jahresabschluss">
                {t("ourServices.title4")}
              </NavDropdown.Item>

              <NavDropdown.Item eventKey="5" as={Link} to="/steuererklaerung">
                {t("ourServices.title5")}
              </NavDropdown.Item>

              <NavDropdown.Item eventKey="6" as={Link} to="/buchhaltung">
                {t("ourServices.title6")}
              </NavDropdown.Item>

              <NavDropdown.Item eventKey="7" as={Link} to="/lohnabrechnung">
                {t("ourServices.title7")}
              </NavDropdown.Item>
              <NavDropdown.Item eventKey="14" as={Link} to="/testamentsvollstreckung">
                {t("ourServices.title8")}
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link as={Link} eventKey="8" className='navlink-menubar' to="/team">{t('header.aboutUs')}</Nav.Link>

            <Nav.Link as={Link} eventKey="9" className='navlink-menubar' to="/karriere">{t('header.career')}</Nav.Link>

            <Nav.Link as={Link} eventKey="10" className='navlink-menubar' to='/kontakt'>{t('header.contact')}</Nav.Link>

            <Nav.Link as={Link} eventKey="13" className='navlink-menubar' to='/mandant-werden'>Mandant werden</Nav.Link>

            <Nav.Link as={Link} eventKey="12" className='navlink-menubar' target="_blank" to='https://webapps.datev.de/duo-start/'>DATEV-Login</Nav.Link>

            <Nav.Link as={Link} eventKey="11" className='navlink-mandanten-dropdown' to='https://online.bailaender.de/' target="_blank" rel="noopener noreferrer">{t('header.portal')}</Nav.Link>
          </Nav>
        </Navbar.Collapse>

        <Nav className='navbar-mandanten'>
          <Nav.Link className='navlink-mandanten' href="https://online.bailaender.de/" target="_blank" rel="noopener noreferrer">{t('header.portal')}</Nav.Link>
        </Nav>

        <img className='navbar-mandanten-bg-img' style={{ margin: 0, zIndex: 1 }} src='../element_mandanten.jpg' alt='element_mandanten' height='85' width='215'></img>

      </Navbar>
    </div>
  );
}

export default Header;