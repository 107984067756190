import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ContactData from "../Shared/Contact.json"
import { Link } from 'react-router-dom';


export const Footer = () => {
  const { t } = useTranslation();
  // const { i18n } = useTranslation();

  return (
    <div className="footer" >
      <Container>
        <Row>
          <Col md={12} lg={3}>
            <a href="/" as={Link}><img src="../logo/bailaender_logo.svg" alt="Das Logo von der Steuerkanzlei Bailänder"></img></a>
          </Col>
        </Row>
        <Row className='footer-firstRow'>
          <Col xs={12} lg={3} className='footer-openingHours'>
            <Row>
              <Col>
                <h2 >
                  {t('footer.opening')}
                </h2>
              </Col>
            </Row>
            <Row>
              <Col xs={6} md={6}>
                <Col xs={{ span: 8, offset: 4 }} md={{ span: 6, offset: 4 }} lg={{ span: 12, offset: 0 }} style={{ textAlign: 'start', padding: 0 }}  >
                  <p>{t('footer.monday')}</p>
                </Col>
              </Col>
              <Col xs={6} md={6}>
                <p>{t('footer.time')}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={6} md={6}>
                <Col xs={{ span: 8, offset: 4 }} md={{ span: 6, offset: 4 }} lg={{ span: 12, offset: 0 }} style={{ textAlign: 'start', padding: 0 }}  >
                  <p>{t('footer.tuesday')}</p>
                </Col>
              </Col>
              <Col xs={6} md={6}>
                <p>{t('footer.time')}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={6} md={6}>
                <Col xs={{ span: 8, offset: 4 }} md={{ span: 6, offset: 4 }} lg={{ span: 12, offset: 0 }} style={{ textAlign: 'start', padding: 0 }}  >
                  <p>{t('footer.wednesday')}</p>
                </Col>
              </Col>
              <Col xs={6} md={6}>
                <p>{t('footer.time')}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={6} md={6}>
                <Col xs={{ span: 8, offset: 4 }} md={{ span: 6, offset: 4 }} lg={{ span: 12, offset: 0 }} style={{ textAlign: 'start', padding: 0 }} >
                  <p>{t('footer.thursday')}</p>
                </Col>
              </Col>
              <Col xs={6} md={6}>
                <p>{t('footer.time')}</p>
              </Col>
            </Row>
            {/*
                        <Row>
                            <Col xs={6} md={6}>
                                <Col xs={{ span: 8, offset: 4 }} md={{ span: 6, offset: 4 }} lg={{ span: 12, offset: 0 }} style={{ textAlign: 'start', padding: 0 }}  >
                                    <p>{t('footer.friday')}</p>
                                </Col>
                            </Col>
                            <Col xs={6} md={6}>
                                <p>8:00 - 13:00 Uhr</p>
                            </Col>
                        </Row>
                        */}
            <Row>
              <Col>
                <p style={{ fontSize: 12 }}>{t('footer.timeinfo')}</p>
              </Col>
            </Row>
          </Col>
          <Col xs={{ span: 8, offset: 2 }} md={{ span: 4, offset: 4 }} lg={{ span: 3, offset: 0 }} className='footer-followUs'>
            <h2>
              {t('footer.followUs')}
            </h2>
            <Row>
              <Col xs={3}>
                <a href='https://www.instagram.com/kanzlei_bailaender/' target="_blank" rel="noopener noreferrer">
                  <img src="../icons/instagram.svg" alt="Instagram Icon"></img>
                </a>
              </Col>
              <Col xs={3}>
                <a href='https://www.facebook.com/Hafeneder.Bailaender/' target="_blank" rel="noopener noreferrer">
                  <img src="../icons/facebook.svg" alt="Facebook Icon"></img>
                </a>
              </Col>
              <Col xs={3}>
                {/* <a href='/' target="_blank" rel="noopener noreferrer">
                                    <img src="../icons/linkedin.svg" alt="LinkedIn Icon"></img>
                                </a> */}
              </Col>
              <Col xs={3}>
                {/* <a href='/' target="_blank" rel="noopener noreferrer">
                                    <img src="../icons/xing.svg" alt="Xing Icon"></img>
                                </a> */}
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={3} className='footer-certificates'>
            <h2>Nützliche Links</h2>
            <Row>
              <a href='https://webapps.datev.de/duo-start/' target="_blank" rel="noopener noreferrer">DATEV Login</a>
            </Row>
            <Row>
              <a href='https://online.bailaender.de/' target="_blank" rel="noopener noreferrer">{t('header.portal')}</a>
            </Row>
            {/* <Row>
              <Col xs={4}>
                <div className='footer-social-certificate'> </div>
              </Col>
              <Col xs={4}>
                <div className='footer-social-certificate'> </div>
              </Col>
              <Col xs={4}>
                <div className='footer-social-certificate'> </div>
              </Col>
              </Row> */}
          </Col>
          <Col xs={12} lg={3} className='footer-contact'>
            <h2>
              {t('footer.contact')}
            </h2>
            <p>
              {t('footer.advisor')} Bailänder <br />
              Ingolstädter Straße 4<br />
              84030 Landshut
            </p>
            <p>
              {ContactData.Telephone[0].info} <br />
              {ContactData.Emails[0].info}
            </p>
            <a
              className='footer-link'
              href="/impressum"
              as={Link}
            >
              {t('footer.imprint')}
            </a><br />
            <a
              className='footer-link'
              href="/datenschutzerklaerung"
              as={Link}
            >
              {t('footer.data')}
            </a>

            {/* Button to switch between the languages */}
            <div style={{ marginTop: 10 }}>
              {/* {Object.keys(lngs).map((lng) => (
                                <button key={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} type="submit" onClick={() => i18n.changeLanguage(lng)}>
                                    {lngs[lng].nativeName}
                                </button>
                            ))} */}

              {/* <button className='language-button' key={"de"} style={{ opacity: i18n.resolvedLanguage === "de" ? '1' : '0.3' }} type="submit" onClick={() => i18n.changeLanguage("de")}>
                                <svg width="30" height="auto" xmlns="http://www.w3.org/2000/svg" viewBox="220 480 1600 1200">
                                    <g id="Layer_1">
                                        <rect x="256" y="562.74" width="1536" height="307.59" />
                                        <rect x="256" y="870.33" width="1536" height="307.59" fill="#d00" />
                                        <rect x="256" y="1177.92" width="1536" height="307.59" fill="#ffce00" />
                                    </g>
                                    <rect width="2048" height="2048" fill="none" />
                                </svg>
                            </button>

                            <button className='language-button' key={"en"} style={{ opacity: i18n.resolvedLanguage === "en" ? '1' : '0.3' }} type="submit" onClick={() => i18n.changeLanguage("en")}>
                                <svg width="30" height="auto" xmlns="http://www.w3.org/2000/svg" viewBox="220 470 1600 1200">
                                    <g id="b"><rect x="256" y="562.5" width="1536" height="923" fill="#eee" />
                                        <polygon points="1284.47 896.22 1792 603.52 1792 562.5 1757.01 562.5 1178.32 896.22 1284.47 896.22" fill="#cf142b" fillRule="evenodd" />
                                        <polygon points="1260.51 1170.31 1792 1476.71 1792 1415.49 1366.69 1170.31 1260.51 1170.31" fill="#cf142b" fillRule="evenodd" />
                                        <polygon points="256 632.76 710.65 896.22 816.88 896.22 256 571.48 256 632.76" fill="#cf142b" fillRule="evenodd" />
                                        <polygon points="792.8 1170.31 256 1480.18 256 1485.5 352.98 1485.5 899.08 1170.31 792.8 1170.31" fill="#cf142b" fillRule="evenodd" />
                                        <polygon points="1686.89 562.5 1149.1 562.5 1149.1 872.78 1686.89 562.5" fill="#00247d" fillRule="evenodd" />
                                        <polygon points="911.63 562.5 375.99 562.5 911.63 872.78 911.63 562.5" fill="#00247d" fillRule="evenodd" />
                                        <polygon points="1792 896.22 1792 684.47 1427.16 896.22 1792 896.22" fill="#00247d" fillRule="evenodd" />
                                        <polygon points="1792 1379.81 1792 1170.31 1427.16 1170.31 1792 1379.81" fill="#00247d" fillRule="evenodd" />
                                        <polygon points="406.39 1485.5 911.63 1485.5 911.63 1193.82 406.39 1485.5" fill="#00247d" fillRule=":evenodd" />
                                        <polygon points="1149.1 1485.5 1655.94 1485.5 1149.1 1193.82 1149.1 1485.5" fill="#00247d" fillRule="evenodd" />
                                        <polygon points="256 1170.31 256 1389.24 633.52 1170.31 256 1170.31" fill="#00247d" fillRule="evenodd" />
                                        <polygon points="256 896.22 633.52 896.22 256 676.97 256 896.22" fill="#00247d" fillRule="evenodd" />
                                        <polygon points="959.14 562.5 959.14 951.05 256 951.05 256 1115.61 959.14 1115.61 959.14 1485.5 1101.59 1485.5 1101.59 1115.61 1792 1115.61 1792 951.05 1101.59 951.05 1101.59 562.5 959.14 562.5" fill="#cf142b" fillRule="evenodd" />
                                    </g>
                                    <rect width="2048" height="2048" fill="none" />
                                </svg>
                            </button> */}





            </div>
          </Col>
        </Row>
        <Row>
          <Col className='small-text' style={{ textAlign: 'center' }}>
            <p >
              {t('footer.copyright')}
              <br />
              <a href='https://adfec.media/' target="_blank" rel="noopener noreferrer">{t('footer.designedBy')}</a>
            </p>
          </Col>

        </Row>
      </Container>
    </div >
  );
}

export default Footer;