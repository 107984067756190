import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export const ImprintPage = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>Imprint Page Page</title>
                <meta name="description" content="Imprint Page for Bailänder" />
            </Helmet>


            <Container>
                <Row className='page-hero'>
                    <Col >
                        <h1 >{t('footer.imprint')}</h1>
                    </Col>
                </Row>
                <Row style={{ marginBottom: 100 }}>
                    <Col >
                        <h3>Informationen gemäß § 5 Telemediengesetz (TMG) und der Verordnung über Informationspflichten für Dienstleistungserbringer (DL-InfoV)Anbieter dieser Website i. S. v. § 5 TMG ist Herr Steuerberater Marcel Bailänder, E-Mail: info@bailaender.de</h3>
                        <p></p>
                        <h3>Steuerberater</h3>
                        <p>Marcel Bailänder<br />
                            Ingolstädter Straße 4  <br />
                            84030 Landshut<br />
                            Telefon: +49 871 922 460<br />
                            E-Mail: info@bailaender.de<br />
                            Internet: www.bailaender.de<br />
                            Umsatzsteuer-Identifikationsnummer: DE327917580<br />
                        </p>
                        <p>Gesetzlich vorgeschriebene Vermögensschaden-Haftpflichtversicherung: HDI Versicherung AG, HDI-Platz 1, 30659 Hannover </p><br />
                        <p></p>
                        <p>Steuerberater: Die Personen, die auf dieser Website als Steuerberater bezeichnet sind, sind in der Bundesrepublik Deutschland als Steuerberater zugelassen und Mitglied der für sie jeweils nach Standort zuständigen Steuerberaterkammer: www.steuerberaterkammer-muenchen.de<br />
                            Zuständige Aufsichtsbehörde: Steuerberaterkammer München Körperschaft des öffentlichen Rechts Nederlinger Str. 9, 80638 München<br />
                            Der Berufsstand der Steuerberater unterliegt im Wesentlichen den nachstehenden berufsrechtlichen Regelungen: Für Steuerberater sind die berufsrechtlichen Regelungen des Steuerberatungsgesetzes (StBerG), der Durchführungsverordnungen zum Steuerberatungsgesetz (DVStB), der Berufsordnung (BOStB) und der Steuerberatervergütungsverordnung (StBVV) maßgeblich. Diese berufsrechtlichen Regelungen können auf der Internetseite (www.bstbk.de) der Bundessteuerberaterkammer eingesehen werden.</p>
                        <p></p>
                        <p>Hinweis gemäß § 36 Abs.1 Nr.1 des Verbraucherstreitbeilegungsgesetzes (VSBG): Ich bin nicht zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle verpflichtet oder nehme daran teil.</p>
                        <p>Räumlicher Geltungsbereich: Der räumliche Geltungsbereich erstreckt sich auf das Gebiet der Bundesrepublik Deutschland und Albanien, Armenien, Aserbeidschan, Bosnien-Herzegowina, Bulgarien, Estland, Georgien, Jugoslawien (Serbien und Montenegro), Kroatien, Lettland, Litauen, Mazedonien, Moldau, Polen, Rumänien, Russische Föderation, Slowakische Republik, Slowenien, Tschechische Republik, Ukraine und Weißrußland sowie vor Gerichten in außereuropäischen Ländern mit Ausnahme der Türkei (§ 53a Absatz1 Nummer 5 DVStB).</p>
                        <p>Haftungshinweis Hyperlinks/elektronische Verweise: Ich übernehme keine Haftung für die Inhalte externer Hyperlinks/elektronischer Verweise und distanziere mich ausdrücklich von den Inhalten aller gelinkten Seiten auf meiner Webseite. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.</p>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default ImprintPage;
