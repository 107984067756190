import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DiamondText from '../Components/DiamondText';
import ServicesTile from '../Components/ServicesTile';
import ServicesData from "../Shared/Services.json"
import ContactForm from '../Components/ContactForm';
import { Image } from 'react-bootstrap';

export const TaxReturnPage = () => {
        const { t } = useTranslation();

        return (
                <>
                        <Helmet>
                                <title>{t('taxReturn.title')}</title>
                                <meta name="description" content="TaxReturn Page for Bailänder" />
                        </Helmet>

                        <Container>
                                <Row className='page-hero'>
                                        <Col xs={12} md={12} lg={{ span: 8, offset: 4 }}>
                                                <Image src='../img/title-images/annual-title-image.jpg' fluid alt='accounting-title' />
                                        </Col>
                                        <Col md={12} lg={7}>
                                                <h1 className='page-heading-subtitle' >{t("taxReturn.subtitle")}</h1>
                                        </Col>
                                </Row>
                        </Container>

                        <Container className='service-page-content'>
                                <Row>
                                        <Col>
                                                <h1>{t('taxReturn.title')}</h1>
                                        </Col>
                                </Row>

                                <Row>
                                        <Col md={12} lg={{ span: 5, offset: 1 }}>
                                                <p className='text-services-pages' >{t('taxReturn.text1')}</p>
                                        </Col>
                                        <Col md={12} lg={5}>
                                                <p className='text-services-pages'>{t('taxReturn.text2')}</p>
                                        </Col>
                                </Row>
                        </Container >

                        <Container className='diamond-section'>
                                <Row>
                                        <h1>{t('header.offer')}</h1>
                                </Row>
                                <Row>
                                        <DiamondText title={t('taxReturn.diamondHeadline1')} description={t('taxReturn.diamondText1')}></DiamondText>
                                        <DiamondText title={t('taxReturn.diamondHeadline2')} description={t('taxReturn.diamondText2')}></DiamondText>
                                        <DiamondText title={t('taxReturn.diamondHeadline3')} description={t('taxReturn.diamondText3')}></DiamondText>

                                        <DiamondText title={t('taxReturn.diamondHeadline4')} description={t('taxReturn.diamondText4')}></DiamondText>
                                        <DiamondText title={t('taxReturn.diamondHeadline5')} description={t('taxReturn.diamondText5')}></DiamondText>
                                        <DiamondText title={t('taxReturn.diamondHeadline6')} description={t('taxReturn.diamondText6')}></DiamondText>

                                        {/* <DiamondText title={t('taxReturn.diamondHeadline7')} description={t('taxReturn.diamondText7')}></DiamondText>
                                        <DiamondText title={t('taxReturn.diamondHeadline8')} description={t('taxReturn.diamondText8')}></DiamondText>
                                        <DiamondText title={t('taxReturn.diamondHeadline9')} description={t('taxReturn.diamondText9')}></DiamondText> */}
                                </Row>

                        </Container>

                        {/*Kontakt Feld*/}
                        <div className='contact-form-wraper'>
                                <Container>
                                        <ContactForm></ContactForm>
                                </Container>
                        </div>

                        <Container className='ServiceTileContainer'>
                                <Row>
                                        <Col xs={12} md={12}>
                                                <h1 className='FurtherServiceHeading'> {t('header.additional')} </h1>
                                        </Col>
                                </Row>
                                <Row className='justify-content-center ServiceTilesRow'>
                                        <Col xs="auto" xl={4}>
                                                <ServicesTile
                                                        key={ServicesData.ServiceList[0].id}
                                                        value={ServicesData.ServiceList[0].value}
                                                        icon={ServicesData.ServiceList[0].icon}
                                                        title={ServicesData.ServiceList[0].title}
                                                        description={ServicesData.ServiceList[0].description}
                                                        link={ServicesData.ServiceList[0].link}
                                                />
                                        </Col>
                                        <Col xs="auto" xl={4}>
                                                <ServicesTile
                                                        key={ServicesData.ServiceList[1].id}
                                                        value={ServicesData.ServiceList[1].value}
                                                        icon={ServicesData.ServiceList[1].icon}
                                                        title={ServicesData.ServiceList[1].title}
                                                        description={ServicesData.ServiceList[1].description}
                                                        link={ServicesData.ServiceList[1].link}
                                                />
                                        </Col>
                                        <Col xs="auto" xl={4}>
                                                <ServicesTile
                                                        key={ServicesData.ServiceList[2].id}
                                                        value={ServicesData.ServiceList[2].value}
                                                        icon={ServicesData.ServiceList[2].icon}
                                                        title={ServicesData.ServiceList[2].title}
                                                        description={ServicesData.ServiceList[2].description}
                                                        link={ServicesData.ServiceList[2].link}
                                                />
                                        </Col>
                                        <Col xs="auto" xl={4}>
                                                <ServicesTile
                                                        key={ServicesData.ServiceList[3].id}
                                                        value={ServicesData.ServiceList[3].value}
                                                        icon={ServicesData.ServiceList[3].icon}
                                                        title={ServicesData.ServiceList[3].title}
                                                        description={ServicesData.ServiceList[3].description}
                                                        link={ServicesData.ServiceList[3].link}
                                                />
                                        </Col>
                                        <Col xs="auto" xl={4} >
                                                <ServicesTile
                                                        key={ServicesData.ServiceList[5].id}
                                                        value={ServicesData.ServiceList[5].value}
                                                        icon={ServicesData.ServiceList[5].icon}
                                                        title={ServicesData.ServiceList[5].title}
                                                        description={ServicesData.ServiceList[5].description}
                                                        link={ServicesData.ServiceList[5].link}
                                                />
                                        </Col>
                                        <Col xs="auto" xl={4}>
                                                <ServicesTile
                                                        key={ServicesData.ServiceList[6].id}
                                                        value={ServicesData.ServiceList[6].value}
                                                        icon={ServicesData.ServiceList[6].icon}
                                                        title={ServicesData.ServiceList[6].title}
                                                        description={ServicesData.ServiceList[6].description}
                                                        link={ServicesData.ServiceList[6].link}
                                                />
                                        </Col>
                                </Row>
                        </Container>
                </>
        )
}

export default TaxReturnPage;