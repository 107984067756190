import { useRef } from "react";


export const FileUploader = ({ handleFile }) => {
        
        const hiddenFileInput = useRef(null);

        
        const handleClick = (event) => {
                hiddenFileInput.current.click();
        };
        
        const handleChange = (event) => {
                const fileUploaded = event.target.files[0];
                handleFile(fileUploaded);
        };

        return (
                <>
                        <button type="undefiend" style={{ marginLeft: 0 }} className="button-orange" onClick={handleClick}>
                                Lebenslauf hochladen
                        </button>
                        <input
                                type="file"
                                onChange={(event) => {
                                        if (event.target.files[0]) {
                                          if (event.target.files[0].size > 1 * 2000 * 1024) {
                                            console.log("File with maximum size of 2MB is allowed");
                                            return false;
                                          }
                                          
                                          handleChange(event)
                                          return event.target.files[0]
                                        }
                                      }}
                                ref={hiddenFileInput}
                                style={{ display: "none" }} 
                        />



                </>
        );
};