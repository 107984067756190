import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

//import Form from 'react-bootstrap/Form';

import emailjs, { send } from "emailjs-com"; //Email service

// import for Translation
//import { useTranslation } from 'react-i18next'; // Translation

export const MandateApplicationPage = () => {
  // Translation
  //const { t, i18n } = useTranslation();

  // State for the form
  const [buttonDisabled, setButtonDisabled] = useState(true); // Button status
  const [checkedBox, setCheckedBox] = useState(false); // Checked boxes
  const [statusText, setStatusText] = useState(""); // Status text
  const [sendText, setSendText] = useState(""); // Send check

  const [nameValue, setNameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [phoneNumberValue, setPhoneNumberValue] = useState("");
  const [streetValue, setStreetValue] = useState("");
  const [plzValue, setPlzValue] = useState("");
  const [cityValue, setCityValue] = useState("");
  //const bedarfVelue = [];
  const [brancheValue, setBrancheValue] = useState("");
  const [companyAgeValue, setCompanyAgeValue] = useState("");
  const [employeesValue, setEmployeesValue] = useState("");
  const [revenueValue, setRevenueValue] = useState("");
  const [profitValue, setProfitValue] = useState("");
  const [taxAdviserValue, setTaxAdviserValue] = useState("");
  const [taxAdviserTextValue, setTaxAdviserTextValue] = useState("");
  const [wealthValue, setWealthValue] = useState("");
  const [paymentArrearsValue, setPaymentArrearsValue] = useState("");
  const [sepaValue, setSepaValue] = useState("");
  const [courtProceedingsValue, setCourtProceedingsValue] = useState("");
  const [taxAuditValue, setTaxAuditValue] = useState("");
  const [importantValue, setImportantValue] = useState("");
  const [expectationsValue, setExpectationsValue] = useState("");
  const [hobbiesValue, setHobbiesValue] = useState("");


  // Functions to handle the form

  // name
  function handleNameChange(event) {
    setNameValue(event.target.value);

  }

  // email
  function handleEmailChange(event) {
    setEmailValue(event.target.value);
  }

  // phone number
  function handlePhoneNumberChange(event) {
    setPhoneNumberValue(event.target.value);

  }

  // street
  function handleStreetChange(event) {
    setStreetValue(event.target.value);

  }

  // plz
  function handlePlzChange(event) {
    setPlzValue(event.target.value);

  }

  // city
  function handleCityChange(event) {
    setCityValue(event.target.value);

  }

  // Steuerberater wechsel
  function handleTaxAdviserTextChange(event) {
    setTaxAdviserTextValue(event.target.value);
  }

  // expectations
  function handleExpectationsChange(event) {
    setExpectationsValue(event.target.value);
  }

  // hobbies
  function handleHobbiesChange(event) {
    setHobbiesValue(event.target.value);
  }

  // Function sets the value of the checkbox and radio buttons
  const checkData = (event) => {

    const brancheForm = document.getElementById('brancheForm');
    brancheForm.querySelectorAll('input[type="radio"]').forEach((radio) => {
      if (radio.checked) {
        setBrancheValue(radio.value);
        console.log(brancheValue);
      }
    });

    const companyAgeForm = document.getElementById('companyAgeForm');
    companyAgeForm.querySelectorAll('input[type="radio"]').forEach((radio) => {
      if (radio.checked) {
        setCompanyAgeValue(radio.value);
        console.log(companyAgeValue);
      }
    });

    const employeesForm = document.getElementById('employeesForm');
    employeesForm.querySelectorAll('input[type="radio"]').forEach((radio) => {
      if (radio.checked) {
        setEmployeesValue(radio.value);
        console.log(employeesValue);
      }
    });

    const revenueForm = document.getElementById('revenueForm');
    revenueForm.querySelectorAll('input[type="radio"]').forEach((radio) => {
      if (radio.checked) {
        setRevenueValue(radio.value);
        console.log(revenueValue);
      }
    });

    const profitForm = document.getElementById('profitForm');
    profitForm.querySelectorAll('input[type="radio"]').forEach((radio) => {
      if (radio.checked) {
        setProfitValue(radio.value);
        console.log(profitValue);
      }
    });

    const wealthForm = document.getElementById('privateWealthForm');
    wealthForm.querySelectorAll('input[type="radio"]').forEach((radio) => {
      if (radio.checked) {
        setWealthValue(radio.value);
        console.log(wealthValue);
      }
    });

    const taxAdviserForm = document.getElementById('taxAdviserForm');
    taxAdviserForm.querySelectorAll('input[type="radio"]').forEach((radio) => {
      if (radio.checked) {
        setTaxAdviserValue(radio.value);
        console.log(taxAdviserValue);
      }
    });

    const paymentArrearsForm = document.getElementById('paymentArrearsForm');
    paymentArrearsForm.querySelectorAll('input[type="radio"]').forEach((radio) => {
      if (radio.checked) {
        setPaymentArrearsValue(radio.value);
        console.log(paymentArrearsValue);
      }
    });

    const sepaForm = document.getElementById('sepaForm');
    sepaForm.querySelectorAll('input[type="radio"]').forEach((radio) => {
      if (radio.checked) {
        setSepaValue(radio.value);
        console.log(sepaValue);
      }
    });

    const courtProceedingsForm = document.getElementById('courtProceedingsForm');
    courtProceedingsForm.querySelectorAll('input[type="radio"]').forEach((radio) => {
      if (radio.checked) {
        setCourtProceedingsValue(radio.value);
        console.log(courtProceedingsValue);
      }
    });

    const taxAuditForm = document.getElementById('taxAuditForm');
    taxAuditForm.querySelectorAll('input[type="radio"]').forEach((radio) => {
      if (radio.checked) {
        setTaxAuditValue(radio.value);
        console.log(taxAuditValue);
      }
    });

    const taxImportanceForm = document.getElementById('taxImportanceForm');
    taxImportanceForm.querySelectorAll('input[type="radio"]').forEach((radio) => {
      if (radio.checked) {
        setImportantValue(radio.value);
        console.log(importantValue);
      }
    });

    if (
      nameValue.trim() === "" ||
      emailValue.trim() === "" ||
      phoneNumberValue.trim() === "" ||
      streetValue.trim() === "" ||
      plzValue.trim() === "" ||
      cityValue.trim() === "" ||
      // brancheValue.trim() === "" ||
      companyAgeValue.trim() === "" ||
      employeesValue.trim() === "" ||
      revenueValue.trim() === "" ||
      profitValue.trim() === "" ||
      wealthValue.trim() === "" ||
      taxAdviserValue.trim() === "" ||
      taxAdviserTextValue.trim() === "" ||
      expectationsValue.trim() === "" ||
      paymentArrearsValue.trim() === "" ||
      sepaValue.trim() === "" ||
      courtProceedingsValue.trim() === "" ||
      taxAuditValue.trim() === "" ||
      importantValue.trim() === "" ||
      hobbiesValue.trim() === ""
    ) {
      // setCheckedBox(true)
      setButtonDisabled(true)
      setStatusText("Bitte füllen Sie alle Felder aus.");
      setCheckedBox(false)
    } else {
      // setCheckedBox(false)
      setButtonDisabled(false)
      setStatusText("");
      setCheckedBox(true)
    }
  }


  // Function to send the email
  const handleSubmit = (event) => {
    event.preventDefault();

    const bedarfForm = document.getElementById('bedarfForm');
    let bedarfVelue = [];
    bedarfForm.querySelectorAll('input[type="checkbox"]').forEach((checkbox) => {
      if (checkbox.checked) {
        bedarfVelue.push(checkbox.value);
        console.log(bedarfVelue);
      }
    });

    // Bestandteile der E-Mail
    const emailParams = {
      name: nameValue,
      email: emailValue,
      phone: phoneNumberValue,
      street: streetValue,
      plz: plzValue,
      city: cityValue,
      bedarf: bedarfVelue,
      branche: brancheValue,
      companyAge: companyAgeValue,
      employees: employeesValue,
      revenue: revenueValue,
      profit: profitValue,
      wealth: wealthValue,
      taxAdviser: taxAdviserValue,
      taxAdviserText: taxAdviserTextValue,
      expectations: expectationsValue,
      paymentArrears: paymentArrearsValue,
      sepa: sepaValue,
      courtProceedings: courtProceedingsValue,
      taxAudit: taxAuditValue,
      taxImportance: importantValue,
      hobbies: hobbiesValue,
    };

    console.log("SENDEN: " + emailParams)
    emailjs.send('service_8gg780v', 'template_nw9wspa', emailParams, 'GzqkOboGS7xukTHZy')
      .then((result) => {
        console.log(result);
      }, (error) => {
        console.log(error)
      })

    setSendText("Ihre Anfrage wurde erfolgreich versendet. Wir werden uns in Kürze bei Ihnen melden.");
    setButtonDisabled(true);
    //sendCheck()

    //Reset der Formulardaten
    // setNameValue("")
    // setEmailValue("")
    // setPhoneNumberValue("")

    // setStreetValue("")
    // setPlzValue("")
    // setCityValue("")
  };

  return (
    <div style={{ backgroundColor: '#2D2A29', marginTop: '-20px', paddingBottom: '200px', color: '#FFFFFF' }}>
      <Container>
        <Row>
          <Col xs={12} md={9}>
            <h1 style={{ color: '#E9521D', marginTop: '150px' }}>Steuerkanzlei Bailänder Fragebogen</h1>
          </Col>
          {/* <Col xs={12} md={9}>
            <p style={{ color: '#FFFFFF' }}>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>
          </Col> */}
        </Row>
      </Container>

      <Container style={{ marginTop: '100px' }}>
        {/* Persönliche/Betriebliche Daten */}
        <Row>
          <h2>Persönliche/Betriebliche Daten</h2>
        </Row>

        <Row>
          <Col>
            <input
              type="name"
              name="name"
              className="form-control"
              id="name"
              placeholder={'Vor- und Nachname'}
              value={nameValue}
              onChange={handleNameChange}
            />

            <input
              type="email"
              name="email"
              className="form-control"
              id="email"
              placeholder={'E-Mail'}
              value={emailValue}
              onChange={handleEmailChange}
            />

            <input
              type="number"
              name="phoneNumber"
              className="form-control"
              id="phoneNumber"
              placeholder={'Telefonnummer'}
              value={phoneNumberValue}
              onChange={handlePhoneNumberChange}
            />
          </Col>

          <Col>
            <input
              type="street"
              name="street"
              className="form-control"
              id="street"
              placeholder={'Adresse'}
              value={streetValue}
              onChange={handleStreetChange}
            />

            <input
              type="number"
              name="zipCode"
              className="form-control"
              id="zipCode"
              placeholder={'PLZ'}
              value={plzValue}
              onChange={handlePlzChange}
            />

            <input
              type="city"
              name="city"
              className="form-control"
              id="city"
              placeholder={'Ort'}
              value={cityValue}
              onChange={handleCityChange}
            />
          </Col>
        </Row>

        {/* Worin besteht bei Ihnen Bedarf? */}
        <Row style={{ marginTop: '100px' }}>
          <h2>Worin besteht bei Ihnen Bedarf?</h2>
          <p className="small-text">(Mehrfach Auswahl ist möglich)</p>
        </Row>

        <Row>
          <form id="bedarfForm">
            <Col>
              <input type="checkbox" id="taxAdvisory" name="taxAdvisory" value="Steuerberatung" />
              <label for="taxAdvisory">Steuerberatung</label>
            </Col>
            <Col>
              <input type="checkbox" id="payAdvisory" name="payAdvisory" value="Gestaltungsberatung" />
              <label for="payAdvisory">Gestaltungsberatung</label>
            </Col>
            <Col>
              <input type="checkbox" id="inheritance" name="inheritance" value="Erbschaft/Schenkung" />
              <label for="inheritance">Erbschaft/Schenkung</label>
            </Col>
            <Col>
              <input type="checkbox" id="taxReturn" name="taxReturn" value="Steuererklärung" />
              <label for="taxReturn">Steuererklärung</label>
            </Col>
            <Col>
              <input type="checkbox" id="annualStatement" name="annualStatement" value="Jahresabschlüsse" />
              <label for="annualStatement">Jahresabschlüsse</label>
            </Col>
            <Col>
              <input type="checkbox" id="accounting" name="accounting" value="Buchhaltung" />
              <label for="accounting">Buchhaltung</label>
            </Col>
          </form>
        </Row>

        {/* In welcher Branche sind Sie tätig? */}
        <Row style={{ marginTop: '100px' }}>
          <h2>In welcher Branche sind Sie tätig?</h2>
        </Row>

        <Row>
          <form id="brancheForm">
            <Col>
              <input
                type="radio"
                id="immobilien"
                value="Immobilien"
                name="branche"
              />
              <label for="immobilien">Immobilien</label>
            </Col>
            <Col>
              <input
                type="radio"
                id="bau"
                value="Bau"
                name="branche"
              />
              <label for="bau">Bau</label>
            </Col>

            <Col>
              <input
                type="radio"
                id="dienstleistung"
                value="Dienstleistung"
                name="branche"
              />
              <label for="dienstleistung">Dienstleistung und Handwerk</label>
            </Col>
            {/* <Col>
              <input
                type="radio"
                id="handwerk"
                value="Handwerk"
                name="branche"
              />
              <label for="handwerk">Handwerk</label>
            </Col> */}
            <Col>
              <input
                type="radio"
                id="handel"
                value="Handel"
                name="branche"
              />
              <label for="handel">Handel</label>
            </Col>
            <Col>
              <input
                type="radio"
                id="finanzmarkt"
                value="Finanzmarkt"
                name="branche"
              />
              <label for="fianzmarkt">Finanzmarkt</label>
            </Col>
          </form>
        </Row>

        {/* Wie lange besteht das Unternehmen bereits? */}
        <Row style={{ marginTop: '100px' }}>
          <h2>Wie lange besteht das Unternehmen bereits?</h2>
        </Row>

        <Row>
          <form id="companyAgeForm">
            <Col>
              <input
                type="radio"
                id="companyAge1"
                name="companyAge"
                value="Weniger als 1 Jahr"
              />
              <label for="companyAge">Weniger als 1 Jahr</label>
            </Col>
            <Col>
              <input
                type="radio"
                id="companyAge2"
                name="companyAge"
                value="1 bis 2 Jahre"
              />
              <label for="companyAge2">1 bis 2 Jahre</label>
            </Col>
            <Col>
              <input
                type="radio"
                id="companyAge3"
                name="companyAge"
                value="2 bis 5 Jahre"
              />
              <label for="companyAge3">2 bis 5 Jahre</label>
            </Col>
            <Col>
              <input
                type="radio"
                id="companyAge4"
                name="companyAge"
                value="5 bis 10 Jahre"
              />
              <label for="companyAge4">5 bis 10 Jahre</label>
            </Col>
            <Col>
              <input
                type="radio"
                id="companyAge5"
                name="companyAge"
                value="Mehr als 10 Jahre"
              />
              <label for="companyAge5">Mehr als 10 Jahre</label>
            </Col>
          </form>
        </Row>

        {/* Wie viele Mitarbeiter sind in Ihrem Unternehmen derzeit beschäftigt? */}
        <Row style={{ marginTop: '100px' }}>
          <h2>Wie viele Mitarbeiter sind in Ihrem Unternehmen derzeit beschäftigt?</h2>
        </Row>

        <Row>
          <form id="employeesForm">
            <Col>
              <input
                type="radio"
                id="employees1"
                name="employees"
                value="1 - 4 Mitarbeiter"
              />
              <label for="employees1">1 bis 4 Mitarbeiter</label>
            </Col>
            <Col>
              <input
                type="radio"
                id="employees2"
                name="employees"
                value="5 - 10 Mitarbeiter"
              />
              <label for="employees2">5 bis 9 Mitarbeiter</label>
            </Col>
            <Col>
              <input
                type="radio"
                id="employees3"
                name="employees"
                value="10 - 24 Mitarbeiter"
              />
              <label for="employees3">10 bis 24 Mitarbeiter</label>
            </Col>
            <Col >
              <input
                type="radio"
                id="employees4"
                name="employees"
                value="25 - 49 Mitarbeiter"
              />
              <label for="employees4">25 bis 49 Mitarbeiter</label>
            </Col>
            <Col >
              <input
                type="radio"
                id="employees5"
                name="employees"
                value="50 oder mehr Mitarbeiter"
              />
              <label for="employees5">50 oder mehr Mitarbeiter</label>
            </Col>
          </form>
        </Row>

        {/* Wie hoch ist Ihr Jahresumsatz? */}
        <Row style={{ marginTop: '100px' }}>
          <h2>Wie hoch ist Ihr Jahresumsatz?</h2>
        </Row>

        <Row>
          <form id="revenueForm">
            <Col>
              <input
                type="radio"
                id="revenue1"
                name="revenue"
                value="unter 100.000 €"
              />
              <label for="revenue1">unter 100.000 €</label>
            </Col>
            <Col>
              <input
                type="radio"
                id="revenue2"
                name="revenue"
                value="100.000 € bis 250.000 €"
              />
              <label for="revenue2">100.000 € bis 250.000 €</label>
            </Col>
            <Col>
              <input
                type="radio"
                id="revenue3"
                name="revenue"
                value="250.000 € bis 500.000 €"
              />
              <label for="revenue3">250.000 € bis 500.000 €</label>
            </Col>
            <Col>
              <input
                type="radio"
                id="revenue4"
                name="revenue"
                value="500.000 € bis 1.000.000 €"
              />
              <label for="revenue4">500.000 € bis 1 Mio. €</label>
            </Col>
            <Col>
              <input
                type="radio"
                id="revenue5"
                name="revenue"
                value="1.000.000 € bis 5.000.000 €"
              />
              <label for="revenue5">1 Mio. € bis 5 Mio. €</label>
            </Col>
            <Col>
              <input
                type="radio"
                id="revenue6"
                name="revenue"
                value="5.000.000 € bis 10.000.000 €"
              />
              <label for="revenue6">5 Mio. € bis 10 Mio. €</label>
            </Col>
            <Col>
              <input
                type="radio"
                id="revenue7"
                name="revenue"
                value="über 10.000.000 €"
              />
              <label for="revenue7">über 10 Mio. €</label>
            </Col>
          </form>
        </Row>

        {/* Wie hoch ist Ihr Gewinn? */}
        <Row style={{ marginTop: '100px' }}>
          <h2>Wie hoch ist Ihr Gewinn?</h2>
        </Row>

        <Row>
          <form id="profitForm">
            <Col>
              <input
                type="radio"
                id="profit1"
                name="profit"
                value="Verlust"
              />
              <label for="profit1">Verlust</label>
            </Col>
            <Col>
              <input
                type="radio"
                id="profit2"
                name="profit"
                value="bis 100.000 €"
              />
              <label for="profit2">bis 100.000 €</label>
            </Col>
            <Col>
              <input
                type="radio"
                id="profit3"
                name="profit"
                value="100.000 € - 500.000 €"
              />
              <label for="profit3">100.000 € bis 500.000 €</label>
            </Col>
            <Col>
              <input
                type="radio"
                id="profit4"
                name="profit"
                value="500.000 € - 1 Mio. €"
              />
              <label for="profit4">500.000 € bis 1 Mio. €</label>
            </Col>
            <Col>
              <input
                type="radio"
                id="profit5"
                name="profit"
                value="über 1 Mio. €"
              />
              <label for="profit5">über 1 Mio. €</label>
            </Col>
          </form>
        </Row>

        {/* Welchen Umfang hat Ihr Privatvermögen (Schätzung inkl. von Immobilien, Aktien, etc.)? */}
        <Row style={{ marginTop: '100px' }}>
          <h2>Welchen Umfang hat Ihr Privatvermögen (Schätzung inkl. von Immobilien, Aktien, etc.)?</h2>
        </Row>

        <Row>
          <form id="privateWealthForm">
            <Col>
              <input
                type="radio"
                id="wealth1"
                name="wealth"
                value="unter 1 Mio. €"
              />
              <label for="wealth1">unter 1 Mio. €</label>
            </Col>
            <Col>
              <input
                type="radio"
                id="wealth2"
                name="wealth"
                value="1 Mio. € - 5 Mio. €"
              />
              <label for="wealth2">1 Mio. € bis 5 Mio. €</label>
            </Col>
            <Col>
              <input
                type="radio"
                id="wealth3"
                name="wealth"
                value="5 Mio. € - 10 Mio. €"
              />
              <label for="wealth3">5 Mio. € bis 10 Mio. €</label>
            </Col>
            <Col>
              <input
                type="radio"
                id="wealth4"
                name="wealth"
                value="über 10 Mio. €"
              />
              <label for="wealth4">über 10 Mio. €</label>
            </Col>
          </form>
        </Row>

        {/* Haben Sie bereits einen Steuerberater? */}
        <Row style={{ marginTop: '100px' }}>
          <h2>Haben Sie bereits einen Steuerberater?</h2>
        </Row>

        <Row>
          <form id="taxAdviserForm">
            <Col>
              <input
                type="radio"
                id="no"
                name="taxAdviser"
                value="Nein"
              />
              <label for="no">Nein</label>
            </Col>
            <Col>
              <input
                type="radio"
                id="yes"
                name="taxAdviser"
                value="Ja"
              />
              <label for="yes">Ja</label>
            </Col>
          </form>
          <Col>
            <p>Grund für den Wechsel:</p>
            <textarea
              rows="4"
              name="taxAdviserName"
              id="taxAdviserName"
              className="form-control"
              value={taxAdviserTextValue}
              onChange={handleTaxAdviserTextChange}
            />
          </Col>
        </Row>

        {/* Welche Erwartungshaltung haben Sie an Ihren neuen Steuerberater? */}
        <Row style={{ marginTop: '100px' }}>
          <h2>Welche Erwartungshaltung haben Sie an Ihren neuen Steuerberater?</h2>
        </Row>

        <Row>
          <Col>
            {/* <input
              type="text-fild"
              name="expectations"
              id="expectations"
              className="form-control"
              value={expectationsValue}
              onChange={handleExpectationsChange}
            /> */}
            <textarea
              rows="6"
              name="expectations"
              id="expectations"
              className="form-control"
              value={expectationsValue}
              onChange={handleExpectationsChange}
            />
          </Col>
        </Row>

        {/* Gibt es beim bisherigen Steuerberater Zahlungsrückstände? */}
        <Row style={{ marginTop: '100px' }}>
          <h2>Gibt es beim bisherigen Steuerberater Zahlungsrückstände?</h2>
        </Row>

        <Row>
          <form id="paymentArrearsForm">
            <Col>
              <input
                type="radio"
                id="paymentNo"
                name="paymentArrears"
                value="Nein, keine Zahlungsrückstände"
              />
              <label for="paymentNo">Nein</label>
            </Col>
            <Col>
              <input
                type="radio"
                id="paymentYes"
                name="paymentArrears"
                value="Ja, es gibt Zahlungsrückstände"
              />
              <label for="paymentYes">Ja</label>
            </Col>
          </form>
        </Row>

        {/* Ist dem Finanzamt eine SEPA-Lastschrift erteilt? */}
        <Row style={{ marginTop: '100px' }}>
          <h2>Ist dem Finanzamt eine SEPA-Lastschrift erteilt?</h2>
        </Row>

        <Row>
          <form id="sepaForm">
            <Col>
              <input
                type="radio"
                id="sepaNo"
                name="sepa"
                value="Nein, keine SEPA-Lastschrift erteilt"
              />
              <label for="sepaNo">Nein</label>
            </Col>
            <Col>
              <input
                type="radio"
                id="sepaYes"
                name="sepa"
                value="Ja, SEPA-Lastschrift erteilt"
              />
              <label for="sepaYes">Ja</label>
            </Col>
          </form>
        </Row>

        {/* Bestehen im Moment laufende Gerichtsverfahren? */}
        <Row style={{ marginTop: '100px' }}>
          <h2>Bestehen im Moment laufende Gerichtsverfahren?</h2>
        </Row>

        <Row>
          <form id="courtProceedingsForm">
            <Col>
              <input
                type="radio"
                id="courtNo"
                name="courtProceedings"
                value="Nein, keine laufenden Gerichtsverfahren"
              />
              <label for="courtNo">Nein</label>
            </Col>
            <Col>
              <input
                type="radio"
                id="courtYes"
                name="courtProceedings"
                value="Ja, laufende Gerichtsverfahren"
              />
              <label for="courtYes">Ja</label>
            </Col>
          </form>
        </Row>

        {/* Hatten Sie in der Vergangenheit bereits eine steuerliche Betriebsprüfung? */}
        <Row style={{ marginTop: '100px' }}>
          <h2>Hatten Sie in der Vergangenheit bereits eine steuerliche Betriebsprüfung?</h2>
        </Row>

        <Row>
          <form id="taxAuditForm">
            <Col>
              <input
                type="radio"
                id="taxAuditNo"
                name="taxAudit"
                value="Nein, es gab noch keine steuerliche Betriebsprüfung"
              />
              <label for="taxAuditNo">Nein</label>
            </Col>
            <Col>
              <input
                type="radio"
                id="taxAuditYes1"
                name="taxAudit"
                value="Ja, mit Nachzahlung"
              />
              <label for="taxAuditYes1">Ja, mit Nachzahlung</label>
            </Col>
            <Col>
              <input
                type="radio"
                id="taxAuditYes2"
                name="taxAudit"
                value="Ja, mit Erstattung"
              />
              <label for="taxAuditYes2">Ja, mit Erstattung</label>
            </Col>
          </form>
        </Row>


        {/* Wie wichtig ist Ihnen allgemein das Thema Steuern? */}
        <Row style={{ marginTop: '100px' }}>
          <h2>Wie wichtig ist Ihnen allgemein das Thema Steuern?</h2>
        </Row>

        <Row>
          <form id="taxImportanceForm">
            <Col>
              <input
                type="radio"
                id="Important1"
                name="taxImportance"
                value="1 (sehr wichtig)"
              />
              <label for="Important1">1 (sehr wichtig)</label>
            </Col>
            <Col>
              <input
                type="radio"
                id="Important2"
                name="taxImportance"
                value="2 (wichtig)"
              />
              <label for="Important2">2 (wichtig)</label>
            </Col>
            <Col>
              <input
                type="radio"
                id="Important3"
                name="taxImportance"
                value="3 (neutral)"
              />
              <label for="Important3">3 (neutral)</label>
            </Col>
          </form>
        </Row>


        {/* Was machen Sie in Ihrer Freizeit? */}
        <Row style={{ marginTop: '100px' }}>
          <h2>Was machen Sie in Ihrer Freizeit?</h2>
        </Row>

        <Row>
          <Col>
            {/* <input
              type="textarea"
              rows="4"
              name="hobbies"
              id="hobbies"
              className="form-control"
              value={hobbiesValue}
              onChange={handleHobbiesChange}
            /> */}
            <textarea
              rows="6"
              name="hobbies"
              id="hobbies"
              className="form-control"
              value={hobbiesValue}
              onChange={handleHobbiesChange}
            />

          </Col>
        </Row>
      </Container>

      {/* Versenden */}
      <Container style={{ marginTop: '100px' }}>
        <input
          type="checkbox"
          id="accept"
          name="accept"
          checked={checkedBox}
          onChange={checkData}
        />
        <label for="accept">Hiermit bestätige ich das alle gemachten Angaben der Warheit entsprechen und diese von der Steuerkanzlei Bailänder verarbeitet werden dürfen.</label>
        <p style={{color: 'red'}}>{statusText}</p>
        <button
          style={{ marginTop: '50px' }}
          onClick={handleSubmit}
          disabled={buttonDisabled}
          className='button-orange'
        >
          Mandantenfragebogen senden
        </button>
        <p style={{color: 'green', textAlign: 'center', marginTop:'20px'}}>{sendText}</p>

      </Container>
    </div>
  )
}

export default MandateApplicationPage;