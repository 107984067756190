import React from "react";
import emailjs from 'emailjs-com';
import { useState, useRef } from 'react';
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const Form = () => {
  const [nameValue, setNameValue] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [messageValue, setMessageValue] = useState("");
  const [buttonStatus, setButtonStatus] = useState(true);
  const form = useRef();
  const { t } = useTranslation();

  function handleNameChange(event) {
    setNameValue(event.target.value);
    if (
      event.target.value.trim() === "" ||
      phoneValue.trim() === "" ||
      emailValue.trim() === "" ||
      messageValue.trim() === ""
    ) {
      setButtonStatus(true)
    } else {
      setButtonStatus(false)
    }
  }
  function handlePhoneChange(event) {
    setPhoneValue(event.target.value);
    if (
      nameValue.trim() === "" ||
      event.target.value.trim() === "" ||
      emailValue.trim() === "" ||
      messageValue.trim() === ""
    ) {
      setButtonStatus(true)
    } else {
      setButtonStatus(false)
    }
  }
  function handleEmailChange(event) {
    setEmailValue(event.target.value);
    if (
      nameValue.trim() === "" ||
      phoneValue.trim() === "" ||
      event.target.value.trim() === "" ||
      messageValue.trim() === ""
    ) {
      setButtonStatus(true)
    } else {
      setButtonStatus(false)
    }
  }
  function handleMessageChange(event) {
    setMessageValue(event.target.value);
    if (
      nameValue.trim() === "" ||
      phoneValue.trim() === "" ||
      emailValue.trim() === ""  ||
      event.target.value.trim() === ""
    ) {
      setButtonStatus(true)
    } else {
      setButtonStatus(false)
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (
      nameValue.trim() === "" ||
      phoneValue.trim() === "" ||
      emailValue.trim() === ""
    ) {

      setButtonStatus(true)
    } else {
      emailjs.sendForm('service_8gg780v', 'template_onsbs7o', form.current, 'GzqkOboGS7xukTHZy')
        .then((result) => {
          console.log(result.text);
        }, (error) => {
          console.log(error.text);
        });

      setNameValue("")
      setPhoneValue("")
      setEmailValue("")
      setMessageValue("")
      setButtonStatus(true)
    }
  }





  return (
    <Col style={{ padding: 0 }}>
      <Row>
        <div>
          {/* <!-- contact form --> */}
          <form ref={form} onSubmit={handleSubmit}>
            {/* <!-- name --> */}
            <div>
              <input
                type="name"
                name="name"
                className="form-control"
                id="name"
                placeholder={t('contact.formname')}
                value={nameValue}
                onChange={handleNameChange}
              />
            </div>

            {/* <!-- E-Mail --> */}
            <div>
              <input
                type="email"
                name="email"
                className="form-control"
                id="email"
                placeholder={t('contact.formemail')}
                value={emailValue}
                onChange={handleEmailChange}
              />
            </div>

            {/* <!-- Telefon --> */}
            <div>
              <input
                type="number"
                name="phone"
                className="form-control"
                id="phone"
                placeholder={t('contact.formtelephone')}
                value={phoneValue}
                onChange={handlePhoneChange}
              />
            </div>

            {/* <!-- Anliegen --> */}
            <div>
              <textarea
                type="text"
                name="message"
                className="form-control"
                id="message"
                placeholder={t('contact.formanliegen')}
                value={messageValue}
                onChange={handleMessageChange}
              />
            </div>

            {/* <!--
            <div class="form-group">
              <label for="email_body">Message</label>
              <textarea
                class="form-control"
                id="email_body"
                rows="5"
              ></textarea>
            </div>
             --> */}

            <button type="submit" disabled={buttonStatus} className="button-orange" style={{ margin: 0, marginTop: 25 }}>
              {t('contact.formbutton')}
            </button>
          </form>
        </div>
      </Row>
    </Col>
  );

}
export default Form;