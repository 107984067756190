import React from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';


function DiamondText(props) {
    const { t } = useTranslation();

    return (
        <Col xl={4}  md={6} xs={12} >
            <div className="diamondText">
                    <svg className="diamondText-image" width="150" height="160" viewBox="0 0 416 512" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className="cls-1" d="m356.31,376.51L414.37,0,57.93,134.91,0,511.52l356.31-135.01Z" fill="#E9521D" />
                    </svg>
                    <h2 className="diamondText-title">{t([props.title])}</h2>
                    <p className="diamondText-description">{t([props.description])}</p>
            </div>
        
        </Col>
    );
}

export default DiamondText;