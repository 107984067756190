import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


export const DataProtectionPage = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>Datenschutzerklärung</title>
                <meta name="description" content="Data Protection Page for Bailänder" />
            </Helmet>



            <Container>
                <Row className='page-hero'>
                    <Col >
                        <h1 >{t('footer.data')}</h1>
                    </Col>

                </Row>
                <Row style={{ marginBottom: 100}}>
                    <Col >
                        <h3>Datenschutzrechtlicher Hinweis:Die Nutzung unserer Website ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.</h3>
                        <p></p>
                        <h3>1. Name und Kontaktdaten des Verantwortlichen</h3>
                        <p>Diese Datenschutzerklärung informiert über die Verarbeitung personenbezogener Daten auf der Kanzleiwebseite von:</p>

                        <h2>Steuerberater Marcel Bailänder</h2>
                        <p>Theaterstraße 67<br></br>
                            84028 Landshut<br></br>
                            Telefon: 0871/922 460<br></br>
                            E-Mail: info@bailaender.de</p>
                        <p>Fragen hinsichtlich des Datenschutzes, die in Zusammenhang mit der Steuerkanzlei Hafeneder | Bailänder stehen, richten Sie bitte per E-Mail an datenschutz@bailaender.de.</p>
                        <p></p>
                        <h3>2. Umfang und Zweck der Verarbeitung personenbezogener Daten</h3>
                        <h3>2.1 Aufruf der Webseite</h3>
                        <p>Beim Aufruf dieser Webseite www.hafeneder-bailaender.de werden durch den Internet-Browser, den der Besucher verwendet, automatisch Daten an den Server dieser Webseite gesendet und zeitlich begrenzt in einer Protokolldatei (Logfile) gespeichert. Bis zur automatischen Löschung werden nachstehende Daten ohne weitere Eingabe des Besuchers gespeichert:</p>
                        <p>IP-Adresse des Endgeräts des Besuchers,Datum und Uhrzeit des Zugriffs durch den Besucher,Name und URL der vom Besucher aufgerufenen Seite,Webseite, von der aus der Besucher auf die Kanzleiwebseite gelangt (sog. Referrer-URL),Browser und Betriebssystem des Endgeräts des Besuchers sowie der Name des vom Besucher verwendeten Access-Providers.</p>
                        <p>Die Verarbeitung dieser personenbezogenen Daten ist gem. Art. 6 Abs. 1 Satz 1 Buchst. f) DSGVO gerechtfertigt. Die Kanzlei hat ein berechtigtes Interesse an der Datenverarbeitung zu dem Zweck, die Verbindung zur Webseite der Kanzlei zügig aufzubauen, eine nutzerfreundliche Anwendung der Webseite zu  ermöglichen, die Sicherheit und Stabilität der Systeme zu erkennen und zu gewährleisten und die Administration der Webseite zu erleichtern und zu verbessern.</p>
                        <p>Die Verarbeitung erfolgt ausdrücklich nicht zu dem Zweck, Erkenntnisse über die Person des Besuchers der Webseite zu gewinnen.</p>
                        <p></p>
                        <h3>3. Weitergabe von Daten</h3>
                        <p>Personenbezogene Daten werden an Dritte übermittelt, wenn</p>
                        <p>nach Art. 6 Abs. 1 Satz 1 Buchst. a) DSGVO durch die betroffene Person ausdrücklich dazu eingewilligt wurde, die Weitergabe nach Art. 6 Abs. 1 Satz 1 Buchst. f) DSGVO zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht, dass die betroffene Person ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe ihrer Daten hat, für die Datenübermittlung nach Art. 6 Abs. 1 Satz 1 Buchst. c) DSGVO eine gesetzliche Verpflichtung besteht, und/oder dies nach Art. 6 Abs. 1 Satz 1 Buchst. b) DSGVO für die Erfüllung eines Vertragsverhältnisses mit der betroffenen Person erforderlich ist.</p>
                        <p>In anderen Fällen werden personenbezogene Daten nicht an Dritte weitergegeben.</p>
                        <p></p>
                        <h3>4. Cookies</h3>
                        <p>Auf der Webseite werden sog. Cookies eingesetzt. Das sind Datenpakete, die zwischen dem Server der Kanzleiwebseite und dem Browser des Besuchers ausgetauscht werden. Diese werden beim Besuch der Webseite von den jeweils verwendeten Geräten (PC, Notebook, Tablet, Smartphone etc.) gespeichert. Cookies können insoweit keine Schäden auf den verwendeten Geräten anrichten. Insbesondere enthalten sie keine Viren oder sonstige Schadsoftware. In den Cookies werden Informationen abgelegt, die sich jeweils im Zusammenhang mit dem spezifisch eingesetzten Endgerät ergeben. Die Kanzlei kann damit keinesfalls unmittelbar Kenntnis von der Identität des Besuchers der Webseite erhalten.</p>
                        <p>Cookies werden nach den Grundeinstellungen der Browser größtenteils akzeptiert. Die Browsereinstellungen können so eingerichtet werden, dass Cookies entweder auf den verwendeten Geräten nicht akzeptiert werden, oder dass jeweils ein besonderer Hinweis erfolgt, bevor ein neuer Cookie angelegt wird. Es wird allerdings darauf hingewiesen, dass die Deaktivierung von Cookies dazu führen kann, dass nicht alle Funktionen der Webseite bestmöglich genutzt werden können.</p>
                        <p>Der Einsatz von Cookies dient dazu, die Nutzung des Webangebots der Kanzlei komfortabler zu gestalten. So kann beispielsweise anhand von Session-Cookies nachvollzogen werden, ob der Besucher einzelne Seiten der Webseite bereits besucht hat. Nach Verlassen der Webseite werden diese Session-Cookies automatisch gelöscht.</p>
                        <p>Zur Verbesserung der Benutzerfreundlichkeit werden temporäre Cookies eingesetzt. Sie werden für einen vorübergehenden Zeitraum auf dem Gerät des Besuchers gespeichert. Bei erneutem Besuch der Webseite wird automatisch erkannt, dass der Besucher die Seite bereits zu einem früheren Zeitpunkt aufgerufen hat und welche Eingaben und Einstellungen dabei vorgenommen wurden, um diese nicht wiederholen zu müssen.</p>
                        <p>Der Einsatz von Cookies erfolgt außerdem, um die Aufrufe der Webseite zu statistischen Zwecken und zum Zwecke der Verbesserung des Angebotes zu analysieren. Diese Cookies ermöglichen es, bei einem erneuten Besuch automatisch zu erkennen, dass die Webseite bereits zuvor vom Besucher aufgerufen wurde. Hier erfolgt nach einer jeweils festgelegten Zeit eine automatische Löschung der Cookies.</p>
                        <p>Die durch Cookies verarbeiteten Daten sind für die o. g. Zwecke zur Wahrung der berechtigten Interessen der Kanzlei nach Art. 6 Abs. 1 Satz 1 Buchst. f) DSGVO gerechtfertigt.</p>
                        <p></p>
                        <h3>5. Analyse Tools und Werbung - Google Analytics</h3>
                        <p>Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.</p>
                        <p>Google Analytics verwendet so genannte "Cookies". Das sind Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert.</p>
                        <p>Die Speicherung von Google-Analytics-Cookies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der Analyse des Nutzerverhaltens, um sowohl sein Webangebot als auch seine Werbung zu optimieren.</p>
                        <p>IP Anonymisierung<br />
                            Wir haben auf dieser Website die Funktion IP-Anonymisierung aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum vor der Übermittlung in die USA gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.</p>
                        <p>Browser Plugin<br />
                            Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch den Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: <a style={{ color: 'black' }} href='https://tools.google.com/dlpage/gaoptout?hl=de'>https://tools.google.com/dlpage/gaoptout?hl=de.</a></p>
                        <p>Widerspruch gegen Datenerfassung<br />
                            Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern, indem Sie auf folgenden Link klicken. Es wird ein Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei zukünftigen Besuchen dieser Website verhindert:</p>
                        <p><a style={{ color: 'black' }} href='https://tools.google.com/dlpage/gaoptout'>Google Analytics deaktivieren</a><br />
                            Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der Datenschutzerklärung von <br />
                            Google: <a style={{ color: 'black' }} href='https://support.google.com/analytics/answer/6004245?hl=de'>https://support.google.com/analytics/answer/6004245?hl=de.</a> </p>
                        <p>Auftragsdatenverarbeitung<br />
                            Wir haben mit Google einen Vertrag zur Auftragsdatenverarbeitung abgeschlossen und setzen die strengen Vorgaben der deutschen Datenschutzbehörden bei der Nutzung von Google Analytics vollständig um.</p>
                        <p>Demografische Merkmale bei Google Analytics<br />
                            Diese Website nutzt die Funktion “demografische Merkmale” von Google Analytics. Dadurch können Berichte erstellt werden, die Aussagen zu Alter, Geschlecht und Interessen der Seitenbesucher enthalten. Diese Daten stammen aus interessenbezogener Werbung von Google sowie aus Besucherdaten von Drittanbietern. Diese Daten können keiner bestimmten Person zugeordnet werden. Sie können diese Funktion jederzeit über die Anzeigeneinstellungen in Ihrem Google-Konto deaktivieren oder die Erfassung Ihrer Daten durch Google Analytics wie im Punkt “Widerspruch gegen Datenerfassung” dargestellt generell untersagen.</p>
                        <p>Google AdWords und Google Conversion-Tracking<br />
                            Diese Website verwendet Google AdWords. AdWords ist ein Online-Werbeprogramm der Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, United States (“Google”).<br />
                            Im Rahmen von Google AdWords nutzen wir das so genannte Conversion-Tracking. Wenn Sie auf eine von Google geschaltete Anzeige klicken wird ein Cookie für das Conversion-Tracking gesetzt. Bei Cookies handelt es sich um kleine Textdateien, die der Internet-Browser auf dem Computer des Nutzers ablegt. Diese Cookies verlieren nach 30 Tagen ihre Gültigkeit und dienen nicht der persönlichen Identifizierung der Nutzer. Besucht der Nutzer bestimmte Seiten dieser Website und das Cookie ist noch nicht abgelaufen, können Google und wir erkennen, dass der Nutzer auf die Anzeige geklickt hat und zu dieser Seite weitergeleitet wurde.<br />
                            Jeder Google AdWords-Kunde erhält ein anderes Cookie. Die Cookies können nicht über die Websites von AdWords-Kunden nachverfolgt werden. Die mithilfe des Conversion-Cookies eingeholten Informationen dienen dazu, Conversion-Statistiken für AdWords-Kunden zu erstellen, die sich für Conversion-Tracking entschieden haben. Die Kunden erfahren die Gesamtanzahl der Nutzer, die auf ihre Anzeige geklickt haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Sie erhalten jedoch keine Informationen, mit denen sich Nutzer persönlich identifizieren lassen. Wenn Sie nicht am Tracking teilnehmen möchten, können Sie dieser Nutzung widersprechen, indem Sie das Cookie des Google Conversion-Trackings über ihren Internet-Browser unter Nutzereinstellungen leicht deaktivieren. Sie werden sodann nicht in die Conversion-Tracking Statistiken aufgenommen.<br />
                            Die Speicherung von “Conversion-Cookies” erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der Analyse des Nutzerverhaltens, um sowohl sein Webangebot als auch seine Werbung zu optimieren.<br />
                            Mehr Informationen zu Google AdWords und Google Conversion-Tracking finden Sie in den Datenschutzbestimmungen von <br />
                            Google:<a style={{ color: 'black' }} href='https://www.google.de/policies/privacy/'>https://www.google.de/policies/privacy/.</a>.<br />
                            Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.
                        </p>
                        <p></p>
                        <h3>6. Plugins und Toolss</h3>
                        <p>Google Maps</p>
                        <p>Diese Seite nutzt über eine API den Kartendienst Google Maps. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.<br />
                            Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP Adresse zu speichern. Diese Informationen werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf diese Datenübertragung.<br />
                            Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote und an einer leichten Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.<br />
                            Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Google: <a style={{ color: 'black' }} href='https://www.google.de/intl/de/policies/privacy/'>https://www.google.de/intl/de/policies/privacy/.</a>.
                        </p>
                        <p></p>
                        <h3>7. Ihre Rechte als betroffene Person</h3>
                        <p>Soweit Ihre personenbezogenen Daten anlässlich des Besuchs unserer Webseite verarbeitet werden, stehen Ihnen als „betroffene Person“ im Sinne der DSGVO folgende Rechte zu:</p>
                        <h3>7.1 Auskunft</h3>
                        <p>Sie können von uns Auskunft darüber verlangen, ob personenbezogene Daten von Ihnen bei uns verarbeitet werden. Kein Auskunftsrecht besteht, wenn die Erteilung der begehrten Informationen gegen die Verschwiegenheitspflicht gem. § 83 StBerG verstoßen würde oder die Informationen aus sonstigen Gründen, insbesondere wegen eines überwiegenden berechtigten Interesses eines Dritten, geheim gehalten werden müssen. Hiervon abweichend kann eine Pflicht zur Erteilung der Auskunft bestehen, wenn insbesondere unter Berücksichtigung drohender Schäden Ihre Interessen gegenüber dem Geheimhaltungsinteresse überwiegen. Das Auskunftsrecht ist ferner ausgeschlossen, wenn die Daten nur deshalb gespeichert sind, weil sie aufgrund gesetzlicher oder satzungsmäßiger Aufbewahrungsfristen nicht gelöscht werden dürfen oder ausschließlich Zwecken der Datensicherung oder der Datenschutzkontrolle dienen, sofern die Auskunftserteilung einen unverhältnismäßig hohen Aufwand erfordern würde und die Verarbeitung zu anderen Zwecken durch geeignete technische und organisatorische Maßnahmen ausgeschlossen ist. Sofern in Ihrem Fall das Auskunftsrecht nicht ausgeschlossen ist und Ihre personenbezogenen Daten von uns verarbeitet werden, können Sie von uns Auskunft über folgende Informationen verlangen:

                            Zwecke der Verarbeitung,Kategorien der von Ihnen verarbeiteten personenbezogenen Daten,Empfänger oder Kategorien von Empfängern, gegenüber denen Ihre personenbezogenen Daten offen gelegt werden, insbesondere bei Empfängern in Drittländern,falls möglich die geplante Dauer, für die Ihre personenbezogenen Daten gespeichert werden oder, falls dies nicht möglich ist, die Kriterien für die Festlegung der Speicherdauer,das Bestehen eines Rechts auf Berichtigung oder Löschung oder Einschränkung der Verarbeitung der Sie betreffenden personenbezogenen Daten oder eines Widerspruchsrechts gegen diese Verarbeitung,das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde für den Datenschutz,sofern die personenbezogenen Daten nicht bei Ihnen als betroffene Person erhoben worden sind, die verfügbaren Informationen über die Datenherkunft,das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und angestrebten Auswirkungen automatisierter Entscheidungsfindungen,im Fall der Übermittlung an Empfänger in Drittländern, sofern kein Beschluss der EU-Kommission über die Angemessenheit des Schutzniveaus nach Art. 45 Abs. 3 DSGVO vorliegt, Informationen darüber, welche geeigneten Garantien gem. Art. 46 Abs. 2 DSGVO zum Schutze der personenbezogenen Daten vorgesehen sind.</p>
                        <h3>7.2 Berichtigung und Vervollständigung</h3>
                        <p>Sofern Sie feststellen, dass uns unrichtige personenbezogene Daten von Ihnen vorliegen, können Sie von uns die unverzügliche Berichtigung dieser unrichtigen Daten verlangen. Bei unvollständigen Sie betreffenden personenbezogenen Daten können sie die Vervollständigung verlangen.</p>
                        <h3>7.3 Löschung</h3>
                        <p>Sie haben ein Recht auf Löschung („Recht auf Vergessenwerden“), sofern die Verarbeitung nicht zur Ausübung des Rechts auf freie Meinungsäußerung, des Rechts auf Information oder zur Erfüllung einer rechtlichen Verpflichtung oder zur Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt, erforderlich ist und einer der nachstehenden Gründe zutrifft:

                            Die personenbezogenen Daten sind für die Zwecke, für die sie verarbeitet wurden, nicht mehr notwendig.Die Rechtfertigungsgrundlage für die Verarbeitung war ausschließlich Ihre Einwilligung, welche Sie widerrufen haben.Sie haben Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten eingelegt, die wir öffentlich gemacht haben.Sie haben Widerspruch gegen die Verarbeitung von uns nicht öffentlich gemachter personenbezogener Daten eingelegt und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor.Ihre personenbezogenen Daten wurden unrechtmäßig verarbeitet.Die Löschung der personenbezogenen Daten ist zur Erfüllung einer gesetzlichen Verpflichtung, der wir unterliegen, erforderlich.

                            Kein Anspruch auf Löschung besteht, wenn die Löschung im Falle rechtmäßiger nicht automatisierter Datenverarbeitung wegen der besonderen Art der Speicherung nicht oder nur mit unverhältnismäßig hohem Aufwand möglich und Ihr Interesse an der Löschung gering ist. In diesem Fall tritt an die Stelle einer Löschung die Einschränkung der Verarbeitung.</p>
                        <h3>7.4 Einschränkung der Verarbeitung</h3>
                        <p>Sie können von uns die Einschränkung der Verarbeitung verlangen, wenn einer der nachstehenden Gründe zutrifft:

                            Sie bestreiten die Richtigkeit der personenbezogenen Daten. Die Einschränkung kann in diesem Fall für die Dauer verlangt werden, die es uns ermöglicht, die Richtigkeit der Daten zu überprüfen.Die Verarbeitung ist unrechtmäßig und Sie verlangen statt Löschung die Einschränkung der Nutzung Ihrer personenbezogenen Daten.Ihre personenbezogenen Daten werden von uns nicht länger für die Zwecke der Verarbeitung benötigt, die Sie jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen.Sie haben Widerspruch gem. Art. 21 Abs. 1 DSGVO eingelegt. Die Einschränkung der Verarbeitung kann solange verlangt werden, wie noch nicht feststeht, ob unsere berechtigten Gründe gegenüber Ihren Gründen überwiegen.

                            Einschränkung der Verarbeitung bedeutet, dass die personenbezogenen Daten nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses verarbeitet werden. Bevor wir die Einschränkung aufheben, haben wir die Pflicht, Sie darüber zu unterrichten.</p>
                        <h3>7.5 Datenübertragbarkeit</h3>
                        <p>Sie haben ein Recht auf Datenübertragbarkeit, sofern die Verarbeitung auf Ihrer Einwilligung (Art. 6 Abs. 1 Satz 1 Buchst. a) oder Art. 9 Abs. 2 Buchst. a) DSGVO) oder auf einem Vertrag beruht, dessen Vertragspartei Sie sind und die Verarbeitung mithilfe automatisierter Verfahren erfolgt. Das Recht auf Datenübertragbarkeit beinhaltet in diesem Fall folgende Rechte, sofern hierdurch nicht die Rechte und Freiheiten anderer Personen beeinträchtigt werden: Sie können von uns verlangen, die personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Sie haben das Recht, diese Daten einem anderen Verantwortlichen ohne Behinderung unserseits zu übermitteln. Soweit technisch machbar, können Sie von uns verlangen, dass wir Ihre personenbezogenen Daten direkt an einen anderen Verantwortlichen übermitteln.</p>
                        <h3>7.6 Widerspruch</h3>
                        <p>Sofern die Verarbeitung auf Art. 6 Abs. 1 Satz 1 Buchst. e) DSGVO (Wahrnehmung einer Aufgabe im öffentlichen Interesse oder in Ausübung öffentlicher Gewalt) oder auf Art. 6 Abs. 1 Satz 1 Buchst. f) DSGVO (berechtigtes Interesse des Verantwortlichen oder eines Dritten) beruht, haben Sie das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten Widerspruch einzulegen. Das gilt auch für ein auf Art. 6 Abs. 1 Satz 1 Buchst. e) oder Buchst. f) DSGVO gestütztes Profiling. Nach Ausübung des Widerspruchsrechts verarbeiten wir Ihre personenbezogenen Daten nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.

                            Sie können jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zu Zwecken der Direktwerbung einlegen. Das gilt auch für ein Profiling, das mit einer solchen Direktwerbung in Verbindung steht. Nach Ausübung dieses Widerspruchsrechts werden wir die betreffenden personenbezogenen Daten nicht mehr für Zwecke der Direktwerbung verwenden.

                            Sie haben die Möglichkeit, den Widerspruch telefonisch, per E-Mail, ggf. per Telefax oder an unsere zu Beginn dieser Datenschutzerklärung aufgeführte Postadresse unserer Kanzlei formlos mitzuteilen.</p>
                        <h3>7.7 Widerruf einer Einwilligung</h3>
                        <p>Sie haben das Recht, eine erteilte Einwilligung jederzeit mit Wirkung für die Zukunft zu widerrufen. Der Widerruf der Einwilligung kann telefonisch, per E-Mail, ggf. per Telefax oder an unsere Postadresse formlos mitgeteilt werden. Durch den Widerruf wird die Rechtmäßigkeit der Datenverarbeitung, die aufgrund der Einwilligung bis zum Eingang des Widerrufs erfolgt ist, nicht berührt. Nach Eingang des Widerrufs wird die Datenverarbeitung, die ausschließlich auf Ihrer Einwilligung beruhte, eingestellt.</p>
                        <h3>7.8 Beschwerde</h3>
                        <p>Wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten rechtswidrig ist, können Sie Beschwerde bei einer Aufsichtsbehörde für den Datenschutz einlegen, die für den Ort Ihres Aufenthaltes oder Arbeitsplatzes oder für den Ort des mutmaßlichen Verstoßes zuständig ist.</p>
                        <h3>8. Stand und Aktualisierung dieser Datenschutzerklärung</h3>
                        <p>Diese Datenschutzerklärung hat den Stand vom 25. Mai 2018. Wir behalten uns vor, die Datenschutzerklärung zu gegebener Zeit zu aktualisieren, um den Datenschutz zu verbessern und/oder an geänderte Behördenpraxis oder Rechtsprechung anzupassen.</p>
                    </Col>
                </Row>

            </Container>


        </>
    )
}

export default DataProtectionPage;
