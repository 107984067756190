import './App.css';
import './fonts.css';
import Router from './Router';
import ReactPixel from 'react-facebook-pixel';
import { useEffect } from 'react';

const advancedMatching = { em: 'simon@adfec.media' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: true, // enable logs
};

function App() {
  useEffect(() => {
    ReactPixel.init('3426767080803273', advancedMatching, options); // Ersetzen Sie 'YOUR_PIXEL_ID' durch Ihre tatsächliche Pixel-ID
    //ReactPixel.pageView(); // Für das Tracking von Seitenaufrufen
  }, []);

  <script id="CookieDeclaration" src="https://consent.cookiebot.com/444d963e-d2dc-4c5b-9fd5-55284c29c043/cd.js" type="text/javascript" async></script>

  return (
    <>

      <Router />
    </>
  );
}

export default App;