import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DiamondText from '../Components/DiamondText';
import ServicesTile from '../Components/ServicesTile';
import ServicesData from "../Shared/Services.json"
import ContactForm from '../Components/ContactForm';
import { Image } from 'react-bootstrap';

export const ExecutionWillPgae = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t('execution.title')}</title>
                <meta name="description" content="Testamentsvollstreckung der Steuerkanzlei Bailänder" />
            </Helmet>

            <Container>
                <Row className='page-hero'>
                    <Col xs={12} md={12} lg={{ span: 8, offset: 4 }}>
                        <Image src='../img/title-images/executionWill-title-image.jpeg' fluid alt='Ein Geschlossener Brief mit einem Wachssiegeil und der aufschrift Testament' />
                    </Col>

                    <Col md={12} lg={7}>
                    <h1 className='page-heading-subtitle' >{t('execution.title')}</h1>
                        {/* <h1 className='page-heading-subtitle' >{t('execution.subtitle')}</h1> */}
                    </Col>
                </Row>
            </Container>

            <Container className='service-page-content'>
                {/* <Row>
                    <Col>
                        <h1>{t('execution.title')}</h1>
                    </Col>
                </Row> */}

                <Row>
                    <Col md={12} lg={{ span: 5, offset: 1 }}>
                        <p className='text-services-pages'>{t('execution.text1')}</p>
                    </Col>

                    <Col md={12} lg={5}>
                        <p className='text-services-pages'>{t('execution.text2')}</p>
                    </Col>
                </Row>
            </Container>

            <Container className='diamond-section'>
                <Row>
                    <h1>{t('execution.offer')}</h1>
                </Row>

                <Row>
                    <DiamondText title={t('execution.diamondHeadline1')} description={t('execution.diamondText1')}></DiamondText>
                    <DiamondText title={t('execution.diamondHeadline2')} description={t('execution.diamondText2')}></DiamondText>
                    <DiamondText title={t('execution.diamondHeadline3')} description={t('execution.diamondText3')}></DiamondText>

                    <DiamondText title={t('execution.diamondHeadline4')} description={t('execution.diamondText4')}></DiamondText>
                    <DiamondText title={t('execution.diamondHeadline5')} description={t('execution.diamondText5')}></DiamondText>
                    <DiamondText title={t('execution.diamondHeadline6')} description={t('execution.diamondText6')}></DiamondText>

                    <DiamondText title={t('execution.diamondHeadline7')} description={t('execution.diamondText7')}></DiamondText>
                    <DiamondText title={t('execution.diamondHeadline8')} description={t('execution.diamondText8')}></DiamondText>
                    <DiamondText title={t('execution.diamondHeadline9')} description={t('execution.diamondText9')}></DiamondText>

                    <DiamondText title={t('execution.diamondHeadline10')} description={t('execution.diamondText10')}></DiamondText>
                </Row>
            </Container>

            <Container className='diamond-section'>
                <Row>
                    <h1>Wie bestimmt man einen Testamentsvollstrecker:</h1>
                    <p>Einen Testamentsvollstrecker kann man wie folgt beauftragen:</p>
                </Row>

                <Row>
                    <DiamondText title={'Im Testament'} description={"Die Person, die ein Testament erstellt, kann eine Person als Testamentvollstrecker bestimmen. Diese Person muss dann nach dem Tod der Testamentserstellerin oder des Testamentserstellers beauftragt werden, indem das Testament von einem Gericht anerkannt wird."}></DiamondText>
                    <DiamondText title={'Gerichtliche Entscheidung'} description={"Wenn kein Testament vorliegt oder keine Person im Testament als Testamentvollstrecker bestimmt wurde, kann ein Gericht einen Testamentvollstrecker bestimmen."}></DiamondText>
                    <DiamondText title={'Vereinbarung'} description={"In einigen Fällen können die Erben einer Person eine Vereinbarung treffen und einen gemeinsamen Testamentvollstrecker bestimmen."}></DiamondText>
                </Row>
            </Container>

            <Container className='diamond-section'>
                <Row>
                    <h1>Welche Risiken bestehen, wenn kein Testamentvollstrecker beauftragt wurde?</h1>
                    <p>Es ist wichtig zu beachten, dass ein Testamentvollstrecker helfen kann, diese Risiken zu minimieren und sicherzustellen, dass der Nachlass nach den Wünschen der verstorbenen Person verwaltet und verteilt wird.</p>
                </Row>
                <Row>
                    <DiamondText title={"Streitigkeiten unter den Erben"} description={"Es kann zu Streitigkeiten unter den Erben kommen, wenn es keine klaren Anweisungen für die Verteilung des Nachlasses gibt."}></DiamondText>
                    <DiamondText title={"Fehlverwaltung des Nachlasses"} description={"Ohne eine Person, die für die Verwaltung des Nachlasses verantwortlich ist, kann es zu Fehlverwaltung und möglicherweise zu finanziellen Verlusten kommen."}></DiamondText>
                    <DiamondText title={"Unklare Verteilung des Nachlasses"} description={"Ohne eine klare Anweisung im Testament oder durch einen gerichtlichen Beschluss kann es schwierig sein, den Nachlass korrekt zu verteilen."}></DiamondText>
                    <DiamondText title={"Verzögerungen bei der Regelung des Nachlasses"} description={"Ohne eine Person, die die Regelung des Nachlasses überwacht, kann es zu Verzögerungen und Verwirrung kommen, was zu finanziellen und emotionalen Belastungen für die Beteiligten führen kann."}></DiamondText>
                    <DiamondText title={"Rechtsstreitigkeiten"} description={"Es kann zu Rechtsstreitigkeiten kommen, wenn es keine klare Anweisung für die Verteilung des Nachlasses oder die Regelung von Streitstreitigkeiten"}></DiamondText>
                </Row>
            </Container>

            <Container className='diamond-section'>
                <Row>
                    <h1>Warum sind wir als Steuerberater für Sie der richtig Testamentvollstrecker?</h1>
                    <p>Einen Steuerberater als Testamentvollstrecker zu beauftragen, kann folgende Vorteile bieten:</p>
                </Row>

                <Row>
                    <DiamondText title={"Fachwissen in Steuerrecht"} description={"Ein Steuerberater hat Fachwissen in Steuerrecht und kann dabei helfen, steuerliche Aspekte des Nachlasses zu berücksichtigen und zu optimieren."}></DiamondText>
                    <DiamondText title={"Erfahrung in Vermögensverwaltung"} description={"Ein Steuerberater hat Fachwissen in Steuerrecht und kann dabei helfen, steuerliche Aspekte des Nachlasses zu berücksichtigen und zu optimieren."}></DiamondText>
                    <DiamondText title={"Unparteiische Entscheidungen"} description={"Als professionelle und unparteiische Person kann ein Steuerberater objektive Entscheidungen treffen und Streitigkeiten unter den Erben vermeiden."}></DiamondText>
                    <DiamondText title={"Einhaltung von Gesetzen und Vorschriften"} description={"Ein Steuerberater kennt die geltenden Gesetze und Vorschriften und kann dafür sorgen, dass alle erforderlichen Schritte zur Regelung des Nachlasses ordnungsgemäß ausgeführt werden."}></DiamondText>
                    <DiamondText title={"Zeit- und kosteneffizient"} description={"Ein Steuerberater kann dabei helfen, Zeit und Kosten zu sparen, indem er eine effiziente Regelung des Nachlasses organisiert und überwacht."}></DiamondText>
                </Row>
            </Container>


            {/*Kontakt Feld*/}
            <div className='contact-form-wraper'>
                <Container>
                    <ContactForm></ContactForm>
                </Container>
            </div>

            <Container className='ServiceTileContainer'>
                <Row>
                    <Col xs={12} md={12}>
                        <h1 className='FurtherServiceHeading'>{t('header.additional')}</h1>
                    </Col>
                </Row>
                <Row className='justify-content-center ServiceTilesRow'>
                    <Col xs="auto" xl={4}>
                        <ServicesTile
                            key={ServicesData.ServiceList[1].id}
                            value={ServicesData.ServiceList[1].value}
                            icon={ServicesData.ServiceList[1].icon}
                            title={ServicesData.ServiceList[1].title}
                            description={ServicesData.ServiceList[1].description}
                            link={ServicesData.ServiceList[1].link}
                        />
                    </Col>
                    <Col xs="auto" xl={4}>
                        <ServicesTile
                            key={ServicesData.ServiceList[2].id}
                            value={ServicesData.ServiceList[2].value}
                            icon={ServicesData.ServiceList[2].icon}
                            title={ServicesData.ServiceList[2].title}
                            description={ServicesData.ServiceList[2].description}
                            link={ServicesData.ServiceList[2].link}
                        />
                    </Col>
                    <Col xs="auto" xl={4}>
                        <ServicesTile
                            key={ServicesData.ServiceList[3].id}
                            value={ServicesData.ServiceList[3].value}
                            icon={ServicesData.ServiceList[3].icon}
                            title={ServicesData.ServiceList[3].title}
                            description={ServicesData.ServiceList[3].description}
                            link={ServicesData.ServiceList[3].link}
                        />
                    </Col>
                    <Col xs="auto" xl={4} >
                        <ServicesTile
                            key={ServicesData.ServiceList[4].id}
                            value={ServicesData.ServiceList[4].value}
                            icon={ServicesData.ServiceList[4].icon}
                            title={ServicesData.ServiceList[4].title}
                            description={ServicesData.ServiceList[4].description}
                            link={ServicesData.ServiceList[4].link}
                        />
                    </Col>
                    <Col xs="auto" xl={4}>
                        <ServicesTile
                            key={ServicesData.ServiceList[5].id}
                            value={ServicesData.ServiceList[5].value}
                            icon={ServicesData.ServiceList[5].icon}
                            title={ServicesData.ServiceList[5].title}
                            description={ServicesData.ServiceList[5].description}
                            link={ServicesData.ServiceList[5].link}
                        />
                    </Col>
                    <Col xs="auto" xl={4}>
                        <ServicesTile
                            key={ServicesData.ServiceList[6].id}
                            value={ServicesData.ServiceList[6].value}
                            icon={ServicesData.ServiceList[6].icon}
                            title={ServicesData.ServiceList[6].title}
                            description={ServicesData.ServiceList[6].description}
                            link={ServicesData.ServiceList[6].link}
                        />
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default ExecutionWillPgae;