import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import JobsDataDE from './../Shared/JobsDE.json';
import JobsDataEN from './../Shared/JobsEN.json';
import JobsField from "../Components/JobsField";
import JobsFieldEducation from "../Components/JobsFieldEducation";


export const Jobs = () => {
    const { t, i18n } = useTranslation();

    return (
        <div className='career-form-wrapper'>
            <Container className="career-form">
                <Row>
                    <Col>
                        <h1>{t('jobs.title')}</h1>
                    </Col>
                </Row>
                <Row style={{ marginTop: 55 }}>
                    <Col md={12} lg={6} xl={4} >
                        <h2>{t('jobs.trainees')}</h2>
                        {i18n.resolvedLanguage === "de"
                            ?
                            JobsDataDE.Jobs.map(item => {
                                return (
                                    item.category === "Auszubildende"
                                        ?
                                        <JobsFieldEducation key={item.id} hours={item.hours} name={item.name} pay={item.pay} hardFacts={item.hardFacts} descriptionBulletpoints={item.descriptionBulletpoints} provide={item.provide} />
                                        :
                                        <></>
                                )
                            })
                            :
                            JobsDataEN.Jobs.map(item => {
                                return (
                                    item.category === "Auszubildende"
                                        ?
                                        <JobsFieldEducation key={item.id} hours={item.hours} name={item.name} pay={item.pay} hardFacts={item.hardFacts} descriptionBulletpoints={item.descriptionBulletpoints} provide={item.provide}/>
                                        :
                                        <></>
                                )
                            })}
                    </Col>

                    <Col md={12} lg={6} xl={4} >
                        <h2>{t('jobs.professionals')}</h2>
                        {i18n.resolvedLanguage === "de"
                            ?
                            JobsDataDE.Jobs.map(item => {
                                return (
                                    item.category === "Berufserfahrene"
                                        ?
                                        <JobsField key={item.id} hours={item.hours} name={item.name} pay={item.pay} hardFacts={item.hardFacts} descriptionBulletpoints={item.descriptionBulletpoints} provide={item.provide}/>
                                        :
                                        <></>
                                )
                            })
                            :
                            JobsDataEN.Jobs.map(item => {
                                return (
                                    item.category === "Berufserfahrene"
                                        ?
                                        <JobsField key={item.id} hours={item.hours} name={item.name} pay={item.pay} hardFacts={item.hardFacts} descriptionBulletpoints={item.descriptionBulletpoints} provide={item.provide}/>
                                        :
                                        <></>
                                )
                            })}
                    </Col>

                    <Col md={12} lg={6} xl={4} >
                        <h2>{t('jobs.students')}</h2>
                        {i18n.resolvedLanguage === "de"
                            ?
                            JobsDataDE.Jobs.map(item => {
                                return (
                                    item.category === "Studenten"
                                        ?
                                        <JobsField key={item.id} hours={item.hours} name={item.name} pay={item.pay} hardFacts={item.hardFacts} descriptionBulletpoints={item.descriptionBulletpoints} provide={item.provide}/>
                                        :
                                        <></>
                                )
                            })
                            :
                            JobsDataEN.Jobs.map(item => {
                                return (
                                    item.category === "Studenten"
                                        ?
                                        <JobsField key={item.id} hours={item.hours} name={item.name} pay={item.pay} hardFacts={item.hardFacts} descriptionBulletpoints={item.descriptionBulletpoints} provide={item.provide}/>
                                        :
                                        <></>
                                )
                            })}
                    </Col>
                </Row>

            </Container>
        </div>
    );
}

export default Jobs;