import './App.css';
import React, { Component } from 'react';
import HomePage from './Pages/HomePage.js';
import CareerPage from './Pages/CareerPage';
import DataProtectionPage from './Pages/DataProtectionPage';
import ImprintPage from './Pages/ImprintPage';
import TeamPage from './Pages/TeamPage';
import AccountingPage from './Pages/AccountingPage';
import AnnualFinancialStatementPage from './Pages/AnnualFinancialStatementPage';
import { TaxReturnPage } from './Pages/TaxReturnPage.js';
import InheritancePage from './Pages/InheritancePage';
import PayrollPage from './Pages/PayrollPage';
import RealEstatePage from './Pages/RealEstatePage';
import TaxOptimizationPage from './Pages/TaxOptimizationPage';
import ExecutionWillPgae from './Pages/ExecutionWillPage.js';
import { Routes, Route } from 'react-router-dom';
import Header from './Components/Header.js';
import Footer from './Components/Footer';
import ContactPage from './Pages/ContactPage';
import ApplicationPage from './Pages/ApplicationPage.js';
import BecomeMandatePage from './Pages/BecomeMandatePage.js';
import MandateApplicationPage from './Pages/MandateApplicationPage.js';
// import TestPage from './Pages/TestPage';





class Router extends Component {

    render() {

        return (
            <>
                <Header />
                
                <Routes>
                    <Route path='/' element={<HomePage />} />
                    <Route path='/datenschutzerklaerung' element={<DataProtectionPage />} />
                    <Route path='/impressum' element={<ImprintPage />} />
                    <Route path='/karriere' element={<CareerPage />} />
                    <Route path='/kontakt' element={<ContactPage />} />
                    <Route path='/team' element={<TeamPage />} />
                    <Route path='/buchhaltung' element={<AccountingPage />} />
                    <Route path='/jahresabschluss' element={<AnnualFinancialStatementPage />} />
                    <Route path='/steuererklaerung' element={<TaxReturnPage />} />
                    <Route path='/erbschaft' element={<InheritancePage />} />
                    <Route path='/lohnabrechnung' element={<PayrollPage />} />
                    <Route path='/immobilien' element={<RealEstatePage />} />
                    <Route path='/steueroptimierung' element={<TaxOptimizationPage />} />
                    <Route path='/testamentsvollstreckung' element={<ExecutionWillPgae />} />
                    <Route path='/bewerbung' element={<ApplicationPage />} />
                    <Route path='/mandant-werden' element={<BecomeMandatePage />} />
                    <Route path='/mandanten-formular' element={< MandateApplicationPage/>} />
                    <Route path='*' element={<HomePage />} />
                </Routes>
                <Footer />
            </>
        );
    }
}

export default Router; 