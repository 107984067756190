import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ContactData from "../Shared/Contact.json"
import { Form } from "../Components/Form";


export const ContactPage = () => {
    const { t } = useTranslation();

    return (
        <div className='contact-page-wraper' style={{ marginTop: '-20px' }}>
            <Helmet>
                <title>{t('contact.title')}</title>
                <meta name="description" content="Contact page for Bailänder" />
            </Helmet>

            <Container>
                <Row>
                    <Col>
                        <h1>{t('contact.title')}</h1>
                    </Col>
                </Row>
                <Row>
                    <Col md={8}>
                        <Row>
                            <Col md={12} style={{ marginTop: '25px' }}>
                                <h2>{t('contact.name')}</h2>
                            </Col>
                            <Col md={6}>
                                <Row>
                                    <Col xs={2}>
                                        <img src='./icons/pin.svg' alt="Icon Telefon, Bailänder anrufen"></img>
                                    </Col>
                                    <Col xs={10}>
                                    <p>Ingolstädter Straße 4<br />84030 Landshut</p>
                                    </Col>
                                </Row>
                            </Col>

                            <Col md={6}>
                                <Row>
                                    <Col xs={2}>
                                        <img src='./icons/phone.svg' alt="Icon Telefon, Bailänder anrufen"></img>
                                    </Col>
                                    <Col xs={10}>
                                        <a href="tel:+499123456789">{ContactData.Telephone[0].info}</a>
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: '25px' }}>
                                    <Col xs={2}>
                                        <img src='./icons/envelope-solid 1.svg' alt='Icon Brief, Email an Bailänder'></img>
                                    </Col>
                                    <Col xs={10}>
                                        <a href="mailto:info@bailaender.de">{ContactData.Emails[0].info}</a>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={4} style={{ marginTop: '25px' }}>
                        <h2>{t('contact.form')}</h2>
                        <Form></Form>
                    </Col>
                </Row >
                <Row>
                    <Col>
                        <h1>{t('contact.parking')}</h1>

                    </Col>
                </Row>
                <Row>
                    <Col style={{ marginTop: '25px', marginBottom: '200px'}}>
                        <h2>Parklätze stehen Ihnen direkt vor der Haustüre zur Verfügung.</h2>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ContactPage;
