import React from 'react';
import { useState } from 'react';
// import { Helmet } from 'react-helmet';
// import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import { use } from 'i18next';
// import { Navigate } from 'react-router-dom';

export const BecomeMandatePage = () => {
  // const { t } = useTranslation();

  const [codeInput, setCodeInput] = useState('');
  const [textDisplay, setTextDisplay] = useState('Bitte geben Sie diesen hier ein:');

  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = '/mandanten-formular'; 
    navigate(path);
  }


  const checkCode = () => {
    if (codeInput === '123456') {
      console.log('Code bestätigt');
      routeChange();
    } else {
      console.log('Code nicht korrekt');
      setTextDisplay('Code nicht korrekt, bitte nochmal versuchen.');
    }
  }

  const handleCodeInput = (e) => {
    setCodeInput(e.target.value);
    
  }

  return (
    <div style={{ backgroundColor: '#2D2A29', marginTop: '-20px', paddingBottom: '200px', color: '#FFFFFF' }}>
      <Container>
        <Row>
          <Col xs={12} md={9}>
            <h1 style={{ color: '#E9521D', marginTop: '400px' }}>So werden Sie bei uns Mandant</h1>
          </Col>
          <Col xs={12} md={9}>
            <p style={{ color: '#FFFFFF' }}>Bevor Sie bei uns Mandant werden, möchten wir, dass wir uns gegenseitig kennenlernen.<br />
            Wir haben den Aufnahmeprozess in vier Schritte gegliedert:</p>
          </Col>
        </Row>
      </Container>

      <Container style={{ marginTop: '120px', marginBottom: '200px' }}>
        <Row>
          <Col xs={12} md={9}>
            <Row>
              <Col>
                <Row>
                  <h1 style={{ color: '#E9521D' }}>Schritt 1:</h1>
                  <h1> Das telefonische Erstgespräch</h1>
                  <p style={{ maxWidth: '680px' }}>Bei der telefonischen Anfrage klären wir sofort ab, ob wir Ihre Branche betreuen. Ihre Mandatsanfrage wird telefonisch entgegengenommen.</p>
                </Row>

                <Row style={{ marginTop: '100px' }}>
                  <h1 style={{ color: '#E9521D' }}>Schritt 2:</h1>
                  <h1>Ihr Zugang zum Mandantenfragebogen</h1>
                  <p style={{ maxWidth: '680px' }}>Sie erhalten von uns Ihre Zugangsdaten zu unserem Mandantenfragebogen.</p>
                </Row>

                <Row style={{ marginTop: '100px' }}>
                  <h1 style={{ color: '#E9521D' }}>Schritt 3:</h1>
                  <h1>Unsere Fragen an Sie</h1>
                  <p style={{ maxWidth: '680px' }}>Auf Grundlage des von Ihnen ausgefüllten Fragebogens, wird entschieden, ob wir als Steuerberater grundsätzlich zu Ihnen passen.</p>
                </Row>

                <Row style={{ marginTop: '100px' }}>
                  <h1 style={{ color: '#E9521D' }}>Schritt 4:</h1>
                  <h1>Aufnahmegespräch</h1>
                  <p style={{ maxWidth: '680px' }}>In unserem Aufnahmegespräch lernen wir uns kennen und die gegenseitige Erwartungshaltung wird besprochen. Zudem wird eruiert welchen konkreten Mehrwert wir Ihnen bieten können.</p>
                </Row>

              </Col>
            </Row>
          </Col>

          <Col xs={12} md={3}>
            <h2>Jetzt Anrufen</h2>
            <p>+49 871 922 460</p>
          </Col>
        </Row>
      </Container >

      <Container style={{ backgroundColor: '#E9521D', marginTop: '100px', padding: '50px', textAlign: 'center'}}>
        <h2>Sie haben einen Code erhalten?</h2>
        <p>{textDisplay}</p>
        <input
          type="text"
          placeholder="Code eingeben"
          onChange={handleCodeInput}
        ></input><br />
        <button
          style={{
            backgroundColor: '#FFFFFF',
            color: '#E9521D',
            border: 'none',
            padding: '10px 20px',
            borderRadius: '5px',
            marginTop: '10px'
          }}

          onClick={checkCode}
        >Code bestätigen</button>

      </Container>
    </div >
  )
}

export default BecomeMandatePage;
