import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import { Form } from "./Form";
import ContactData from "../Shared/Contact.json"

export const ContactForm = () => {
    const { t } = useTranslation();

    return (
        <Container className="contact-form">
            <Row>
                <Col className="contact-form-title-col">
                    <h1  className="h1text">{t('contact.title')}</h1>
                </Col>
            </Row>
            <Row>
                <Col xs={12} lg={4}>

                </Col>
                <Col xs={12} lg={4} className="contact-form-infos-col">
                    <h2 className="h2text">Steuerkanzlei Bailänder</h2>
                    <div className="form-icons">
                        <img src='./icons/phone.svg' alt="Icon Telefon, Bailänder anrufen"></img> <a href={ContactData.Telephone[0].info}>{ContactData.Telephone[0].info}</a><br />
                    </div>
                    <div className="form-icons">
                        <img src='./icons/envelope-solid 1.svg' alt='Icon Brief, Email an Bailänder'></img> <a href="mailto:info@bailaender.de">{ContactData.Emails[0].info}</a>
                    </div>

                </Col>
                <Col xs={12} lg={4} >
                    <h2 className="h2text">{t('contact.form')}</h2>
                    <Form></Form>
                </Col>
            </Row>

        </Container>
    );
}

export default ContactForm;