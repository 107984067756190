import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ContactData from "../Shared/Contact.json"
import Form from 'react-bootstrap/Form';
import DatePicker from "react-datepicker";
import { FileUploader } from '../Components/FileUploader';
import emailjs, { send } from "emailjs-com";

import "react-datepicker/dist/react-datepicker.css";
import JobsDataDE from './../Shared/JobsDE.json';
import JobsDataEN from './../Shared/JobsEN.json';
// import ContactData from "../Shared/Contact.json"
// import { Form } from "../Components/Form";

//import ReactPixel from 'react-facebook-pixel';


export const ApplicationPage = () => {
  const { t, i18n } = useTranslation();
  const [startDate, setStartDate] = useState(new Date());
  const [buttonStatus, setButtonStatus] = useState(true);
  const [jobValue, setJobValue] = useState("");
  const [salaryValue, setSalaryValue] = useState("");
  const [nameValue, setNameValue] = useState("");
  const [startDateValue, setStartDateValue] = useState("");
  const [emailValue, setEmailValue] = useState("");

  const [file, setFile] = useState(null);

  const [visability, setVisability] = useState("not-visible");

  const [fileName, setFileName] = useState("");
  const handleFile = (file) => {
    console.log("FILE" + file.name + file)
    setFileName(file.name);
    setFile(file)
  };

  function handleSalaryChange(event) {
    setSalaryValue(event.target.value);
  }

  function handleJobChange(event) {
    setJobValue(event.target.value);
  }

  function handleNameChange(event) {
    setNameValue(event.target.value);
    if (
      event.target.value.trim() === "" ||
      emailValue.trim() === "" ||
      startDateValue.toString().trim() === ""
    ) {
      setButtonStatus(true)
    } else {
      setButtonStatus(false)
    }
  }

  function handleEmailChange(event) {
    setEmailValue(event.target.value);
    if (
      nameValue.trim() === "" ||
      event.target.value.trim() === "" ||
      startDateValue.toString().trim() === ""
    ) {
      setButtonStatus(true)
    } else {
      setButtonStatus(false)
    }
  }

  function handleDateChange(event) {
    console.log(event)
    setStartDate(event)
    var date = new Date(event).toLocaleDateString();
    setStartDateValue(date);
    if (
      nameValue.trim() === "" ||
      emailValue.trim() === "" ||
      event.toString().trim() === ""
    ) {
      setButtonStatus(true)
    } else {
      setButtonStatus(false)
    }
  }


  const handleSubmit = (event) => {

    event.preventDefault();
    if (
      nameValue.trim() === "" ||
      startDateValue.toString().trim() === "" ||
      emailValue.trim() === ""
    ) {
      setButtonStatus(true)
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = async (e) => {
        const emailParams = {
          name: nameValue,
          email: emailValue,
          job: jobValue,
          startDate: startDateValue,
          salary: salaryValue,
          file: reader.result
        };
        console.log("SENDEN: " + emailParams)
        // emailjs.send('service_8gg780v', 'template_vad9pco', emailParams, 'GzqkOboGS7xukTHZy')
        //   .then((result) => {
        //     console.log(result);
        //   }, (error) => {
        //     console.log(error)
        //   })
      }
      sendCheck()
      setNameValue("")
      setSalaryValue("")
      setEmailValue("")
      setButtonStatus(true)
    }
  };

  function sendCheck() {
    console.log("is visible")
    setVisability("is-visible")
  }

  return (
    <div className='contact-page-wraper' style={{ marginTop: '-20px' }}>
      <Helmet>
        <title>{t('application.title')}</title>
        <meta name="description" content="Application page for Bailänder" />
      </Helmet>

      <Container>
        <Row style={{ marginBottom: 100 }}>
          <Col xs={12} md={8}>
            <h1 style={{ color: '#E9521D', marginBottom: 15 }}>{t('application.title')}</h1>
            <p>{t('application.subtitle')}</p>
          </Col>
        </Row>

        <Row>
          <Col style={{ marginBottom: 70 }} md={5}>
            <h2 >{t('application.field_job')}</h2>

            <Form.Select
              className='application_form_select'
              style={{ paddingRight: 20 }}
              aria-label="Default select example"
              value={jobValue}
              onChange={handleJobChange}
            >
              <option value="Initiativbewerbung">Initiativbewerbung</option>
              <option value="Auszubildender zum Steuerfachangestellten (m/w/d)">Auszubildender zum Steuerfachangestellten (m/w/d)</option>
              <option value="Buchhalter (m/w/d)">Buchhalter (m/w/d)</option>
              <option value="Steuerfachwirt (m/w/d)">Steuerfachwirt (m/w/d)</option>
              <option value="Steuerberater (m/w/d)">Steuerberater (m/w/d)</option>
              <option value="Rechtsanwalt (m/w/d)">Rechtsanwalt (m/w/d)</option>
              {i18n.resolvedLanguage === "de"
                ?

                JobsDataDE.Jobs.map(item => {
                  return (
                    <>
                      <option key={item.id}>{item.name}</option>
                    </>
                  );
                })
                :
                JobsDataEN.Jobs.map(item => {
                  return (
                    <>
                      <option key={item.id}>{item.name}</option>
                    </>
                  );
                })}
            </Form.Select>
          </Col>

          <Col style={{ marginBottom: 70 }} md={{ span: 6, offset: 1 }}>
            <h2>{t('application.field_salary')}</h2>

            <input
              type="number"
              name="salary"
              className="form-control"
              id="salary"
              placeholder={t('Gehaltsvorstellung')}
              value={salaryValue}
              onChange={handleSalaryChange}
            />
          </Col>
        </Row >

        <Row >
          <Col style={{ marginBottom: 70 }} md={5}>
            <h2>{t('application.field_name')}</h2>

            <input
              type="name"
              name="name"
              className="form-control"
              id="name"
              placeholder={t('Vor- und Nachname')}
              value={nameValue}
              onChange={handleNameChange}
            />
          </Col>

          <Col style={{ marginBottom: 70 }} md={{ span: 6, offset: 1 }}>
            <h2 style={{ marginBottom: 31 }}>{t('application.field_time')}</h2>

            <DatePicker
              className='application_datepicker'
              selected={startDate}
              onChange={(date) => handleDateChange(date)}
              dateFormat="dd.MM.yyyy"
            />
          </Col>
        </Row >

        <Row >
          <Col style={{ marginBottom: 70 }} md={5}>
            <h2>{t('application.field_email')}</h2>

            <input
              type="email"
              name="email"
              className="form-control"
              id="email"
              placeholder={t('E-Mail')}
              value={emailValue}
              onChange={handleEmailChange}
            />
          </Col>

          <Col style={{ marginBottom: 70 }} md={{ span: 6, offset: 1 }}>
            <h2 style={{ marginBottom: -2 }}>{t('application.field_cv')}</h2>
            <p style={{ marginBottom: 25 }}>(Nur PDF Dateien mit maximaler Dateigröße von 2 MB)</p>

            <FileUploader handleFile={handleFile} />
            {fileName ? <><p style={{ marginTop: 10, marginBottom: -2 }}>Hochgeladene Datei:</p>  <p>{fileName}</p></> : null}
          </Col>
        </Row >

        <Row className="justify-content-md-center" style={{ paddingBottom: 150 }}>
          <Col md={'auto'}>
            <h2 style={{ textAlign: 'center', color: '#E9521D' }}>{t('application.button_title')}</h2>
            <p className='small-text' style={{ textAlign: 'center' }}>{t('application.button_subtitle')} </p>
            <button
              onClick={handleSubmit}
              disabled={buttonStatus}
              className='button-orange'
            >
              {t('application.button_text')}
            </button>
            <p className={visability}>Deine Bewerbung wurde erfolgreich verschickt!</p>
          </Col>
        </Row >

        <Row className="justify-content-md-center" style={{ paddingBottom: 50 }}>
          <Col md={'auto'}>
            <h1 style={{ color: '#E9521D' }}>{t('application.questions')}</h1>
          </Col>
        </Row >

        <Row className="justify-content-center" style={{ paddingBottom: 100 }}>
          <Col md={'auto'} style={{ paddingBottom: 25 }}>
            <img style={{ marginRight: 20 }} src='./icons/phone.svg' alt="Icon Telefon, Bailänder anrufen"></img>

            <a href="tel:+499123456789">{ContactData.Telephone[0].info}</a>
          </Col>

          <Col md={'auto'} className="justify-content-md-center">
            <img style={{ marginRight: 20 }} src='./icons/envelope-solid 1.svg' alt='Icon Brief, Email an Bailänder'></img>
            <a href="mailto:bewerbung@bailaender.de">bewerbung@bailaender.de</a>
          </Col>
        </Row >

      </Container >

    </div >
  )
}

export default ApplicationPage;