import { Col, Row } from "react-bootstrap";
import { Modal, Container } from 'react-bootstrap';
import { useState } from 'react';
import ContactData from "../Shared/Contact.json"
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function JobsField(props) {
    const { t } = useTranslation();

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (
        <>
            <Row className="job-field" style={{ height: '100px', lineHeight: '100px' }} onClick={handleShow}>
                <Col xs={12} md={9} style={{ paddingLeft: 20 }}>
                    <h3 className="h3-job-orange" style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal' }}>{props.name}</h3>
                </Col>

                <Col xs={12} md={3} style={{ textAlign: 'right', paddingRight: 20 }}>
                    <p className="p-job-orange" style={{ display: 'inline-block', lineHeight: 'normal' }}>{props.hours}</p>
                </Col>
            </Row>

            <Modal
                fullscreen={'md-down'}
                size="xl"
                dialogClassName="modal-custom"
                aria-labelledby="contained-modal-title-vcenter"
                centered show={show} onHide={handleClose}>
                <Modal.Header className="modal-custom-header" closeVariant="white" closeButton>

                </Modal.Header>
                <Modal.Body className="modal-custom-body">
                    <Container>
                        <Row style={{ marginBottom: 20 }}>
                            <Col md={12} lg={6}>
                                <h2>{props.name}</h2>
                            </Col>
                            <Col md={12} lg={4}>

                            </Col>
                            <Col md={12} lg={2}>
                                <p className="modal-custom-p-hours">{props.hours}</p>
                            </Col>
                        </Row>

                        <Row style={{ marginBottom: 75 }}>

                            <Col md={12} lg={7}>
                                <p>{t('jobs.aboutUs')}</p>
                                <h3>{t('jobs.headline1')}</h3>
                                <ul>
                                    <li><p>{t('jobs.bulletpoint1')}</p></li>
                                    <li><p>{t('jobs.bulletpoint2')}</p></li>
                                    <li><p>{t('jobs.bulletpoint3')}</p></li>
                                    <li><p>{t('jobs.bulletpoint4')}</p></li>
                                </ul>

                                <h3>{t('jobs.headline2')}</h3>
                                <ul>
                                    {props.hardFacts.split('/n').map(c => {
                                        return (<li><p> {c} </p></li>)
                                    })}
                                </ul>
                        
                                <h3>{t('jobs.tasks')}</h3>
                                <p>{t('jobs.tasksSubline')}</p>
                                <ul>
                                    {props.descriptionBulletpoints.split('/n').map(c => {
                                        return (<li><p> {c} </p></li>)
                                    })}
                                </ul>

                                <h3>{t('jobs.youProvide')}</h3>
                                <ul>
                                    {props.provide.split('/n').map(c => {
                                        return (<li><p> {c} </p></li>)
                                    })}
                                </ul>
                            </Col>
                            <Col md={12} lg={1}>


                            </Col>
                            <Col md={12} lg={4}>
                                <Row style={{ marginBottom: 30 }}>
                                    <Col xs={2} >
                                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.66211 1.44151C9.21094 0.351663 8.02148 -0.228415 6.88477 0.0821317L1.72852 1.48838C0.708984 1.76963 0 2.69541 0 3.7501C0 18.2462 11.7539 30.0001 26.25 30.0001C27.3047 30.0001 28.2305 29.2911 28.5117 28.2716L29.918 23.1153C30.2285 21.9786 29.6484 20.7892 28.5586 20.338L22.9336 17.9942C21.9785 17.5958 20.8711 17.8712 20.2207 18.6739L17.8535 21.5626C13.7285 19.6114 10.3887 16.2716 8.4375 12.1466L11.3262 9.78526C12.1289 9.12901 12.4043 8.02744 12.0059 7.07237L9.66211 1.44737V1.44151Z" fill="#E9521D" />
                                        </svg>

                                    </Col>
                                    <Col xs={10} >
                                        <p>{ContactData.Telephone[0].info}</p>
                                    </Col>
                                </Row>
                                <Row style={{ marginBottom: 30 }}>
                                    <Col xs={2} >
                                        <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M2.8125 0.75C1.25977 0.75 0 2.00977 0 3.5625C0 4.44727 0.416016 5.2793 1.125 5.8125L13.875 15.375C14.543 15.873 15.457 15.873 16.125 15.375L28.875 5.8125C29.584 5.2793 30 4.44727 30 3.5625C30 2.00977 28.7402 0.75 27.1875 0.75H2.8125ZM0 7.3125V19.5C0 21.5684 1.68164 23.25 3.75 23.25H26.25C28.3184 23.25 30 21.5684 30 19.5V7.3125L17.25 16.875C15.9141 17.877 14.0859 17.877 12.75 16.875L0 7.3125Z" fill="#E9521D" />
                                        </svg>
                                    </Col>
                                    <Col xs={10} >
                                        <p>{ContactData.Emails[0].bewerbung}</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Link to='/bewerbung' onClick={() => {
                                window.scroll({
                                    top: 0,
                                    left: 0,
                                    behavior: 'auto',
                                })
                            }}>
                                <button style={{ width: 180 }} variant="dark" className="button-orange">jetzt Bewerben</button>
                            </Link>
                        </Row>
                    </Container>

                </Modal.Body>

            </Modal>
        </>
    );

}

export default JobsField;