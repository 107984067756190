import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next';
import Container from 'react-bootstrap/Container';
import { Row, Col, Image } from 'react-bootstrap';
import DiamondText from '../Components/DiamondText';
import ServicesTile from '../Components/ServicesTile';
import ServicesData from "../Shared/Services.json"
import ContactForm from '../Components/ContactForm';

export const AccountingPage = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t('accounting.title')}</title>
                <meta name="description" content="Accounting Page for Bailänder" />
            </Helmet>

            <Container className='page-hero'>
                <Row>
                    <Col xs={12} md={12} lg={{ span: 8, offset: 4 }}>
                        <Image src='../img/title-images/accounting-title-image.jpg' fluid alt='accounting-title'></Image>
                    </Col>
                    <Col md={12} lg={7}>
                        <h1 className='page-heading-subtitle'>{t('accounting.subtitle')}</h1>
                    </Col>
                </Row>
            </Container>

            <Container className='service-page-content'>
                <Row>
                    <Col>
                        <h1>{t('accounting.title')}</h1>
                    </Col>
                </Row>

                <Row>
                    <Col md={12} lg={{ span: 5, offset: 1 }}>
                        <p className='text-services-pages'>{t('accounting.text1')}</p>
                        <p className='textListing'>{t('accounting.textlisting1')}
                            <br />
                            {t('accounting.textlisting2')}
                            <br />
                            {t('accounting.textlisting3')}
                        </p>
                    </Col>

                    <Col md={12} lg={5}>
                        <p className='text-services-pages'>{t('accounting.text2')}</p>
                    </Col>
                </Row>
            </Container>

            <Container className='diamond-section'>
                <Row>
                    <Col xl={12}>
                        <h1 style={{ marginBottom: 50 }}>{t('header.offer')}</h1>
                    </Col>
                </Row>

                <Row>
                    <DiamondText title={t('accounting.diamondHeadline1')} description={t('accounting.diamondText1')}></DiamondText>
                </Row>
            </Container>

            {/*Kontakt Feld*/}
            <div className='contact-form-wraper'>
                <Container>
                    <ContactForm></ContactForm>
                </Container>
            </div>

            <Container className='ServiceTileContainer'>
                <Row>
                    <Col xs={12} md={12}>
                        <h1 className='FurtherServiceHeading'> {t('header.additional')}</h1>
                    </Col>
                </Row>
                <Row className='justify-content-center ServiceTilesRow'>
                    <Col xs="auto" xl={4}>
                        <ServicesTile
                            key={ServicesData.ServiceList[0].id}
                            value={ServicesData.ServiceList[0].value}
                            icon={ServicesData.ServiceList[0].icon}
                            title={ServicesData.ServiceList[0].title}
                            description={ServicesData.ServiceList[0].description}
                            link={ServicesData.ServiceList[0].link}
                        />
                    </Col>
                    <Col xs="auto" xl={4}>
                        <ServicesTile
                            key={ServicesData.ServiceList[1].id}
                            value={ServicesData.ServiceList[1].value}
                            icon={ServicesData.ServiceList[1].icon}
                            title={ServicesData.ServiceList[1].title}
                            description={ServicesData.ServiceList[1].description}
                            link={ServicesData.ServiceList[1].link}
                        />
                    </Col>
                    <Col xs="auto" xl={4}>
                        <ServicesTile
                            key={ServicesData.ServiceList[2].id}
                            value={ServicesData.ServiceList[2].value}
                            icon={ServicesData.ServiceList[2].icon}
                            title={ServicesData.ServiceList[2].title}
                            description={ServicesData.ServiceList[2].description}
                            link={ServicesData.ServiceList[2].link}
                        />
                    </Col>
                    <Col xs="auto" xl={4}>
                        <ServicesTile
                            key={ServicesData.ServiceList[3].id}
                            value={ServicesData.ServiceList[3].value}
                            icon={ServicesData.ServiceList[3].icon}
                            title={ServicesData.ServiceList[3].title}
                            description={ServicesData.ServiceList[3].description}
                            link={ServicesData.ServiceList[3].link}
                        />
                    </Col>
                    <Col xs="auto" xl={4} >
                        <ServicesTile
                            key={ServicesData.ServiceList[4].id}
                            value={ServicesData.ServiceList[4].value}
                            icon={ServicesData.ServiceList[4].icon}
                            title={ServicesData.ServiceList[4].title}
                            description={ServicesData.ServiceList[4].description}
                            link={ServicesData.ServiceList[4].link}
                        />
                    </Col>
                    <Col xs="auto" xl={4}>
                        <ServicesTile
                            key={ServicesData.ServiceList[6].id}
                            value={ServicesData.ServiceList[6].value}
                            icon={ServicesData.ServiceList[6].icon}
                            title={ServicesData.ServiceList[6].title}
                            description={ServicesData.ServiceList[6].description}
                            link={ServicesData.ServiceList[6].link}
                        />
                    </Col>
                </Row>

            </Container>
        </>
    )
}

export default AccountingPage;